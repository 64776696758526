import { default as MuiSlider } from "@mui/material/Slider";

interface IComponent {
  onChangeHandler: any;
  onChangeCommittedHandler: any;
}

interface ISlider extends IComponent {
  range: [number, number];
  value: [number, number];
  inverted?: boolean;
  step?: number;
}

const Slider: React.FC<ISlider> = ({
  range,
  value,
  inverted = false,
  step,
  onChangeHandler,
  onChangeCommittedHandler,
}): JSX.Element => {
  let [left, right] = range;
  let [min, max] = value;

  let marks = [
    { value: left, label: left },
    { value: right, label: right },
  ];
  if (min > left) marks.push({ value: min, label: min });
  if (max < right) marks.push({ value: max, label: max });

  if (inverted) {
    marks = marks.map(({ value, label }) => ({
      value: invert(value),
      label,
    }));
    [left, right] = [invert(right), invert(left)];
    [min, max] = [invert(max), invert(min)];
  }
  const onChange = (e: any, v: any) => {
    onChangeHandler(e, inverted ? [invert(v[1]), invert(v[0])] : v);
  };
  const onChangeCommitted = (e: any, v: any) => {
    onChangeCommittedHandler(e, inverted ? [invert(v[1]), invert(v[0])] : v);
  };

  return (
    <MuiSlider
      value={[min, max]}
      {...{
        marks,
        min: left,
        max: right,
        step: step || (range[1] - range[0]) / 10,
        onChange,
        onChangeCommitted,
      }}
    />
  );
};

export default Slider;

const invert = (e: number) => e * -1;
