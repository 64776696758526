import React from "react";

import Box from "@mui/material/Box";

import {
  AufbauFilter,
  AusstattungFilter,
  FarbeAussenFilter,
  FinanceFilter,
  FzartFilter,
  GetriebeFilter,
  ErstzulassungFilter,
  KilometerFilter,
  KraftstoffFilter,
  LocationFilter,
  MarkeModellGruppeFilter,
  LeistungFilter,
  TuerenFilter,
  SitzeFilter,
} from "./components/filters";

interface iFilter {
  disableMake: boolean;
}

const Filter: React.FC<iFilter> = ({
  disableMake = false,
}): JSX.Element | null => {
  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
        paddingRight: "1rem",
      }}
    >
      <FinanceFilter />
      <MarkeModellGruppeFilter disabled={disableMake} />
      <LocationFilter />
      <ErstzulassungFilter />
      <KilometerFilter />
      <AusstattungFilter />
      <AufbauFilter />
      <FzartFilter />
      <LeistungFilter />
      <GetriebeFilter />
      <KraftstoffFilter />
      <FarbeAussenFilter />
      {process.env.REACT_APP_VEHICLE_TYPE !== "krad" && <TuerenFilter />}
      {process.env.REACT_APP_VEHICLE_TYPE !== "krad" && <SitzeFilter />}
    </Box>
  );
};

export default Filter;
