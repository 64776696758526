import { TVehicle } from "types";

interface IAufbauReducer {
  vehicles: TVehicle[];
  filter: any;
}

const AufbauReducer = ({ vehicles, filter }: IAufbauReducer) => {
  if (!(filter.aufbau && filter.aufbau.length)) return vehicles;
  return vehicles.filter(
    ({ aufbau }) => aufbau && filter.aufbau.includes(aufbau.id)
  );
};

export default AufbauReducer;
