import { denormalize } from "normalizr";
import { vehicleListSchema } from "schemas";

import { TVehicle } from "types";

import { json2normalized } from "./helper";

import Catalog from "./catalog.json";

const NCatalog = json2normalized(Catalog);

const NVehicles = Object.entries(NCatalog.entities.vehicle);

const TVehicles: TVehicle[] = denormalize(
  NCatalog.result.vehicle,
  vehicleListSchema,
  NCatalog.entities
);

export { Catalog, NCatalog, NVehicles, TVehicles };
