import React from "react";
import { FormattedMessage } from "react-intl";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { TVehicle } from "types";

import Button from "@mui/material/Button";

import Detail from "../Finance/Detail";
import { Tupel } from "components";

import Share from "../Share";

interface ISummary {
  paperProps?: any;
  vehicle: TVehicle;
  financeFilter: any;
  financeRate: any;
  action: any;
}

const Summary: React.FC<ISummary> = ({
  paperProps = { sx: { p: 2 } },
  vehicle,
  financeFilter,
  financeRate,
  action: actionHandler,
}): JSX.Element => {
  const { modell } = vehicle;
  return (
    <Paper variant="outlined" {...paperProps}>
      <Box sx={{ position: "relative" }}>
        <Share
          sx={{ position: "absolute", top: 0, right: 0 }}
          vehicle={vehicle}
        />
      </Box>
      <Typography
        variant="h5"
        sx={{
          width: 0.9,
          textOverflow: "ellipsis",
          overflow: "hidden",
          wordWrap: "no-break",
        }}
      >
        {modell.marke.name} {modell.name}
      </Typography>
      <Typography
        gutterBottom
        variant="h6"
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          wordWrap: "no-break",
        }}
      >
        {vehicle.version}
      </Typography>
      <Tupel
        labelId="filter.aufbau.label"
        textId={`aufbau.${vehicle.aufbau.id}.label`}
      />
      <Tupel
        labelId="filter.fzart.label"
        textId={`fzart.${vehicle.fzart.id}.label`}
      />
      <Tupel labelId="common.offerNumber" text={vehicle.wgnr} />
      <Detail {...{ vehicle, financeRate }} short />
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Button variant="contained" onClick={() => actionHandler()}>
          <FormattedMessage
            id="common.buyCar"
            defaultMessage="Fahrzeug kaufen"
          />
        </Button>
        <Button
          variant="contained"
          href="#Offer"
          color="secondary"
          sx={{ ml: 3 }}
        >
          <FormattedMessage
            id="pages.detail.offer.title"
            defaultMessage="Fahrzeug anfragen"
          />
        </Button>
      </Box>
    </Paper>
  );
};

export default Summary;
