import React from "react";
import { FormattedMessage } from "react-intl";

import TextField from "@mui/material/TextField";

interface IReactHookFormText {
  name: string;
  register: any;
  error?: any;
  required?: boolean;
  type?: string;
  prefix?: string;
  label?: string;
  labelId?: string;
  defaultValue?: string | number;
  props?: any;
  inputProps?: any;
  disabled?: boolean;
}

const ReactHookFormText: React.FC<IReactHookFormText> = ({
  register = () => null,
  name,
  error = "",
  required = true,
  type = "text",
  prefix = "",
  defaultValue,
  props = {},
  inputProps = {},
  label,
  labelId,
  disabled = false,
}): JSX.Element => {
  const inputElement = React.useRef<any>();
  const formerDisabled = React.useRef<boolean>(disabled);
  React.useEffect(() => {
    if (formerDisabled.current && !disabled) focusInput();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  const focusInput = () => inputElement.current?.focus();

  return (
    <TextField
      {...register(`${prefix}${name}`)}
      {...props}
      label={
        label ? (
          label
        ) : (
          <FormattedMessage
            id={labelId || `forms.${name}.label`}
            defaultMessage={label || ""}
          />
        )
      }
      error={!!error}
      defaultValue={defaultValue}
      type={type}
      helperText={
        error &&
        typeof error === "string" && (
          <FormattedMessage
            id={
              error === "required"
                ? "forms.help.required"
                : `forms.${name}.help.${error}`
            }
          />
        )
      }
      InputLabelProps={{
        shrink: ["date", "number"].includes(type) || undefined,
      }}
      inputProps={{
        ...inputProps,
        "data-testid": `TextField-${name}`,
        ref: inputElement,
        sx: {
          backgroundColor: required ? "#00dd0011" : "transparent",
        },
      }}
      required={required}
      disabled={disabled}
    />
  );
};

export default ReactHookFormText;
