import { TVehicle } from "types";

interface IMarkeReducer {
  vehicles: TVehicle[];
  filter: any;
}

const MarkeReducer = ({ vehicles, filter }: IMarkeReducer) => {
  if (!(filter.marke && filter.marke.length)) return vehicles;
  return vehicles.filter(
    ({
      modell: {
        id: modellId,
        marke: { id: markeId },
      },
    }) => {
      if (filter.modell && filter.modell.includes(modellId)) return true;
      return filter.marke.includes(markeId);
    }
  );
};

export default MarkeReducer;
