import React from "react";
import { denormalize } from "normalizr";
import { useNavigate, Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import dayjs from "dayjs";
import Markdown from "react-markdown";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Before from "@mui/icons-material/Edit";

import { CheckoutStore, CartStore, CatalogStore } from "stores";
import { vehicleInListSchema } from "schemas";

import {
  AddressSummary,
  BankSummary,
  ContactSummary,
  EmploymentSummary,
  IdCardSummary,
  LivingConditionsSummary,
  NameSummary,
  OriginSummary,
} from "../../components/summaries";

import MFA from "./MFA";

const Summary: React.FC = (): JSX.Element | null => {
  const [orderId, setOrderId] = React.useState<string | undefined>();
  const navigate = useNavigate();
  const { state: checkout } = React.useContext(CheckoutStore);
  const { state: cart } = React.useContext(CartStore);
  const {
    state: { entities },
  } = React.useContext(CatalogStore);

  if (!checkout.buyer) return null;

  const oneIsUsed = !!cart.vehicles.find(({ id }) => {
    console.log(entities);
    const vehicle = denormalize(id, vehicleInListSchema, entities);
    return vehicle.fzart.name === "Gebrauchtwagen";
  });

  const oneIsFinanced = cart.vehicles.find(
    ({ finance }) => finance.type !== "buy"
  );

  const purchase = async () => {
    const vehicles = cart.vehicles.map(({ id, finance }) => {
      return {
        vehicle: denormalize(id, vehicleInListSchema, entities),
        finance,
      };
    });

    const url = `https://api.carbando.eu/checkout`;

    const customer = process.env.REACT_APP_SHOP_ID;
    const variant = process.env.REACT_APP_VEHICLE_TYPE;
    const vehicle = vehicles[0];
    const { finance } = vehicle;

    const payload = {
      customer,
      variant,
      finance,
      vehicle: vehicle.vehicle,
      buyer: checkout.buyer,
      owner: checkout.owner,
    };
    const options = { method: "POST", body: JSON.stringify(payload) };

    const response = await fetch(url, options);

    if (response.ok) {
      const result = await response.text();
      setOrderId(result);
    } else {
      alert("HTTP-Error: " + response.status);
    }
  };

  const checkMFA = async (code: string) => {
    const url = `https://api.carbando.eu/checkout`;
    const customer = process.env.REACT_APP_SHOP_ID;

    const query = `?id=${orderId}&challenge=${code}&customer=${customer}`;
    const response = await fetch(url + query);

    if (response.ok) {
      navigate(`/checkout/conclusion?id=${orderId}`);
      return true;
    } else {
      return false;
    }
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} lg={4}>
        <Card sx={{ pb: 2 }}>
          <CardHeader
            title={
              <FormattedMessage id="common.buyer" defaultMessage="Käufer" />
            }
          />
          <CardContent>
            <NameSummary {...checkout.buyer} />
            <ContactSummary {...checkout.buyer.contact} />
            <OriginSummary {...checkout.buyer} />
            <br />
            <AddressSummary {...checkout.buyer.address} />
            {checkout.buyer.previousAddress &&
              dayjs().subtract(3, "year") <
                dayjs(checkout.buyer.address.residentSince) && (
                <>
                  <Typography variant="h6" mt={3}>
                    Vorherige Adresse
                  </Typography>
                  <AddressSummary {...checkout.buyer.previousAddress} />
                </>
              )}
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              component={Link}
              to="/checkout/registration"
              startIcon={<Before />}
            >
              <FormattedMessage id="common.change" defaultMessage="ändern" />
            </Button>
          </CardActions>
        </Card>
      </Grid>
      {!checkout.buyerIsOwner && checkout.owner && (
        <Grid item xs={12} lg={4}>
          <Card sx={{ pb: 2 }}>
            <CardHeader title="Halter" />
            <CardContent>
              <NameSummary {...checkout.owner} />
              <ContactSummary {...checkout.owner.contact} />
              <OriginSummary {...checkout.owner} />
              <AddressSummary {...checkout.owner.address} />
            </CardContent>
            <CardActions sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                component={Link}
                to="/checkout/registration"
                startIcon={<Before />}
              >
                <FormattedMessage id="common.change" defaultMessage="ändern" />
              </Button>
            </CardActions>
          </Card>
        </Grid>
      )}
      {checkout.buyer.selfdisclosure && oneIsFinanced && (
        <Grid item xs={12} lg={4} sx={{ mb: 2 }}>
          <Card sx={{ pb: 2 }}>
            <CardHeader title="Selbstauskunft" />

            <CardContent>
              <LivingConditionsSummary
                {...checkout.buyer.selfdisclosure?.livingConditions}
              />
              {/* <FinancialBalanceSummary {...checkout.buyer} /> */}
              <br />
              <EmploymentSummary
                {...checkout.buyer.selfdisclosure.employment}
              />
              <br />
              <IdCardSummary {...checkout.buyer.selfdisclosure.idCard} />
              <br />
              <BankSummary {...checkout.buyer.selfdisclosure.bankAccount} />
            </CardContent>
            <CardActions sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                component={Link}
                to="/checkout/disclosure"
                startIcon={<Before />}
              >
                <FormattedMessage id="common.change" defaultMessage="ändern" />
              </Button>
            </CardActions>
          </Card>
        </Grid>
      )}
      <Grid item xs={12}>
        <Container maxWidth="md">
          <Approval usedCar={oneIsUsed} approvalHandler={purchase} />
        </Container>
      </Grid>
      <MFA
        open={!!orderId}
        handleClose={() => setOrderId(undefined)}
        handleSubmit={checkMFA}
      />
    </Grid>
  );
};

interface IApproval {
  approvalHandler: any;
  usedCar: boolean;
}

const Approval: React.FC<IApproval> = ({
  approvalHandler = () => null,
  usedCar = false,
}): JSX.Element => {
  const [withdrawal, setWithdrawal] = React.useState<boolean>(false);
  const [termsAndConditions, setTermsAndConditions] =
    React.useState<boolean>(false);
  const [dataProtection, setDataProtection] = React.useState<boolean>(false);
  const { formatMessage: t } = useIntl();
  return (
    <Card
      sx={{
        p: { xs: 1, md: 2 },
        a: {
          color: "black",
          textDecoration: "none",
          fontWeight: "bold",
        },
        "a:hover": {
          textDecoration: "underline",
        },
      }}
    >
      <Typography variant="h6">Bitte bestätigen Sie die Daten</Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={withdrawal}
              onChange={() => setWithdrawal(!withdrawal)}
            />
          }
          label={
            <Markdown>
              {t(
                {
                  id: "pages.checkout.summary.setWithdrawal.label",
                  defaultMessage:
                    'Ich habe die [Widerrufsbelehrung](https://cdn.carbando.de/{customer}/Widerrufsbelehrung.pdf "Widerrufsbelehrung") gelesen.',
                },
                { customer: process.env.REACT_APP_CUSTOMER || "carbando" }
              )}
            </Markdown>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={termsAndConditions}
              onChange={() => setTermsAndConditions(!termsAndConditions)}
            />
          }
          label={
            <Markdown>
              {t(
                {
                  id: "pages.checkout.summary.setTermsAndConditions.label",
                  defaultMessage:
                    'Ich habe die [allgemeinen Geschäftsbedingungen](https://cdn.carbando.eu/{customer}/AGB.pdf "allgemeinen Geschäftsbedingungen") gelesen und akzeptiere diese.',
                },
                {
                  customer: process.env.REACT_APP_CUSTOMER || "carbando",
                  fzart: usedCar ? "gebrauchtwagen" : "neuwagen",
                }
              )}
            </Markdown>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={dataProtection}
              onChange={() => setDataProtection(!dataProtection)}
            />
          }
          label={
            <Markdown>
              {t(
                {
                  id: "pages.checkout.summary.setDataProtection.label",
                  defaultMessage:
                    'Ich habe die [Datenschutzerklärung](https://cdn.carbando.de/{customer}/Datenschutz.pdf "Datenschutzerklärung") gelesen.',
                },
                { customer: process.env.REACT_APP_CUSTOMER || "carbando" }
              )}
            </Markdown>
          }
        />

        <Box sx={{ typography: "body1" }}>
          <Markdown>
            {t(
              {
                id: "pages.checkout.summary.reservation.label",
                defaultMessage:
                  'Wir haben Ihnen das Produkt für 10 Minuten zurückgelegt. Bitte schließen Sie Ihre Bestellung innerhalb dieses Zeitraums ab, um das gewünschte Produkt auch sicher zu bekommen! Weitere Informationen dazu finden Sie in unserer [Datenschutzerklärung](https://cdn.carbando.de/{customer}/Datenschutz.pdf "Datenschutzerklärung").',
              },
              { customer: process.env.REACT_APP_CUSTOMER || "carbando" }
            )}
          </Markdown>
        </Box>
      </FormGroup>
      <Box sx={{ textAlign: "center" }}>
        <Button
          sx={{ px: 5, m: 2 }}
          disabled={!(withdrawal && termsAndConditions && dataProtection)}
          variant="contained"
          size="large"
          color="success"
          onClick={() => approvalHandler()}
        >
          <FormattedMessage
            id="pages.checkout.summary.bindingOrder.label"
            defaultMessage="VERBINDLICH BESTELLEN"
          />
        </Button>
      </Box>
    </Card>
  );
};

export default Summary;
