import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object } from "yup";
import { FormattedMessage } from "react-intl";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import SpeedDialIcon from "@mui/icons-material/PhoneCallback";
import CloseIcon from "@mui/icons-material/Close";

import { TReactHookForm } from "types";
import { ReactHookFormText, ReactHookFormIntPhone } from "components";

interface IForm {
  closeDialog?: any;
}
const Form: React.FC<IForm> = ({ closeDialog }): JSX.Element => {
  const {
    register,
    control,
    formState: { errors, isValid },
    getValues,
  } = useForm({
    mode: "all",
    resolver: yupResolver(ValidationSchema),
    defaultValues: {},
  });

  const submit = async () => {
    const url = `https://service.carbando.eu/dump`;
    const payload = getValues();
    const options = { method: "POST", body: JSON.stringify(payload) };

    const response = await fetch(url, options);
    if (response.ok) {
      const result = await response.text();
      alert(result);
    } else {
      alert("HTTP-Error: " + response.status);
    }
    closeDialog && closeDialog();
  };

  return (
    <form noValidate>
      <ContactForm
        {...{
          register,
          control,
          errors,
        }}
        errors={errors}
      />
      <Box sx={{ textAlign: "right" }}>
        <Button
          disabled={!isValid}
          variant="contained"
          sx={{ mt: 2 }}
          onClick={() => submit()}
        >
          <FormattedMessage
            id="common.submit.label"
            defaultMessage="Absenden"
          />
        </Button>
      </Box>
    </form>
  );
};

interface IOverlay {
  open: boolean;
  closeOverlay?: any;
}
const Overlay: React.FC<IOverlay> = ({ closeOverlay, open = false }) => {
  const closeDialog = () => {
    closeOverlay && closeOverlay();
  };

  return (
    <Dialog open={open} onClose={() => closeDialog()}>
      <IconButton
        aria-label="delete"
        size="small"
        sx={{ position: "absolute", right: 2, top: 2 }}
        onClick={() => closeDialog()}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>
        Bitte rufen Sie mich unter folgender Nummer zurück
      </DialogTitle>
      <DialogContent>
        <Form closeDialog={() => closeDialog()} />
      </DialogContent>
    </Dialog>
  );
};

const module = {
  speedDialIcon: <SpeedDialIcon />,
  overlay: Overlay,
};

const ValidationSchema = object().shape({
  phone: string()
    .required()
    .matches(
      /^\+491(51|60|70|71|75|52|62|72|73|74|57|63|77|78|59|76|79)\d{7}\d*$/
    ),

  message: string(),
});

const ContactForm = ({
  register,
  control,
  errors,
}: TReactHookForm): JSX.Element => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <ReactHookFormIntPhone
          control={control}
          name="phone"
          countryDisabled
          required
          error={errors?.phone?.type}
        />
      </Grid>
      <Grid item xs={12}>
        <ReactHookFormText
          register={register}
          name="message"
          label="Nachricht"
          required={false}
          error={errors?.message?.type}
          props={{ fullWidth: true, multiline: true, rows: 4 }}
        />
      </Grid>
    </Grid>
  );
};

export default module;
