import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";

import { TVehicle } from "types";
import { Tupel } from "components";

interface IEmissions {
  vehicle: TVehicle;
}

export const Elektro: React.FC<IEmissions> = ({ vehicle }): JSX.Element => (
  <>
    <Typography variant="h6">
      <FormattedMessage
        id="term.stromverbrauch"
        defaultMessage="Stromverbrauch bei rein elektrischem Betrieb"
      />
    </Typography>
    <Tupel
      labelId="term.combined"
      label="kombiniert"
      float={vehicle.wltp_stromverbrauch_kombiniert_reiner_batteriebetrieb}
      unit="kWh/100km"
      footnote={1}
      boxProps={{ fontWeight: "bold" }}
    />
    <Tupel
      labelId="term.innenstadt"
      label="Innenstadt"
      float={vehicle.wltp_stromverbrauch_langsam_reiner_batteriebetrieb}
      unit="kWh/100km"
      footnote={1}
    />
    <Tupel
      labelId="term.stadtrand"
      label="Stadtrand"
      float={vehicle.wltp_stromverbrauch_mittel_reiner_batteriebetrieb}
      unit="kWh/100km"
      footnote={1}
    />
    <Tupel
      labelId="term.landstrasse"
      label="Landstraße"
      float={vehicle.wltp_stromverbrauch_schnell_reiner_batteriebetrieb}
      unit="kWh/100km"
      footnote={1}
    />
    <Tupel
      labelId="term.autobahn"
      label="Autobahn"
      float={vehicle.wltp_stromverbrauch_sehr_schnell_reiner_batteriebetrieb}
      unit="kWh/100km"
      footnote={1}
    />

    <Typography variant="h6" sx={{ mt: 3 }}>
      <FormattedMessage
        id="term.Kraftstoffverbrauch"
        defaultMessage="Kraftstoffverbrauch bei entladener Batterie"
      />
    </Typography>
    <Tupel
      labelId="term.combined"
      label="kombiniert"
      float={vehicle.wltp_kombiniert_entladen}
      unit="l/100km"
      footnote={1}
      boxProps={{ fontWeight: "bold" }}
    />
    <Tupel
      labelId="term.innenstadt"
      label="Innenstadt"
      float={vehicle.wltp_langsam_entladen}
      unit="l/100km"
      footnote={1}
    />
    <Tupel
      labelId="term.stadtrand"
      label="Stadtrand"
      float={vehicle.wltp_mittel_entladen}
      unit="l/100km"
      footnote={1}
    />
    <Tupel
      labelId="term.landstrasse"
      label="Landstraße"
      float={vehicle.wltp_schnell_entladen}
      unit="l/100km"
      footnote={1}
    />
    <Tupel
      labelId="term.autobahn"
      label="Autobahn"
      float={vehicle.wltp_sehr_schnell_entladen}
      unit="l/100km"
      footnote={1}
    />
  </>
);

export default Elektro;
