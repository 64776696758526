import React from "react";
import { FormattedMessage } from "react-intl";
import { Map, Marker, ZoomControl } from "pigeon-maps";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { ConfigurationStore } from "containers";

import { Tupel } from "components";

interface IDealer {
  paperProps?: any;
}

const Dealer: React.FC<IDealer> = ({
  paperProps = { sx: { p: 2 } },
}): JSX.Element | null => {
  const [coordinates, setCoordinates] = React.useState<any>();

  const { dealer }: any = React.useContext(ConfigurationStore);

  React.useEffect(() => {
    const loadCoordinates = async (payload: any) => {
      const url = "https://service.carbando.eu/locate";
      const options = { method: "POST", body: JSON.stringify(payload) };
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.lat && data.lon) setCoordinates(data);
    };
    if (!dealer?.location) return;
    const { lat, lon, zip } = dealer.location;
    if (lat && lon) {
      setCoordinates({ lat, lon });
    } else if (zip) {
      loadCoordinates(dealer.location);
    }
  }, [dealer]);

  if (!dealer) return null;

  const {
    name,
    location: { address = "", zip = "", city = "" },
    contact: { phone },
  } = dealer;

  return (
    <Paper variant="outlined" {...paperProps}>
      <Typography variant="h5">
        <FormattedMessage id="common.dealer" defaultMessage="Händler" />
      </Typography>
      <Tupel label="Standort" text={name} />
      <Tupel label="Straße" text={address} />
      <Tupel label="Ort" text={`${zip} ${city}`} />
      {phone && <Tupel label="Telefon" text={phone} />}
      {coordinates && (
        <Box sx={{ height: "300px", mt: 2, border: 1 }}>
          <Map
            defaultCenter={[coordinates.lat, coordinates.lon]}
            defaultZoom={14}
          >
            <ZoomControl />
            <Marker
              width={30}
              color="green"
              anchor={[coordinates.lat, coordinates.lon]}
            />
          </Map>
        </Box>
      )}
    </Paper>
  );
};

export default Dealer;
