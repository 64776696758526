import React from "react";
import { Link } from "react-router-dom";
import { denormalize } from "normalizr";

import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";

import { CatalogStore } from "stores";
import { cookies } from "./HistoryLogger";

import { vehicleListSchema } from "schemas";
import { TVehicle } from "types";

interface IHistoryPage {
  ids: (number | string)[];
  entities: any;
  sx?: any;
  link?: any;
}
export const HistoryPage: React.FC<IHistoryPage> = ({
  ids = [],
  entities,
  sx = {},
  link = () => null,
}): JSX.Element | null => {
  if (!ids.length) return null;
  const vehicles = denormalize(ids, vehicleListSchema, entities);
  return (
    <Box data-testid="container-history-page">
      <ImageList cols={5} rowHeight={200}>
        {vehicles.map((vehicle: TVehicle) => (
          <Link to={link && link(vehicle.wgnr)} key={vehicle.wgnr}>
            <HistoryItem vehicle={vehicle} />
          </Link>
        ))}
      </ImageList>
    </Box>
  );
};

interface IHistoryItem {
  vehicle: TVehicle;
  sx?: any;
}

const HistoryItem: React.FC<IHistoryItem> = ({
  vehicle,
  sx = {},
}): JSX.Element | null => {
  if (!(vehicle && vehicle.pictures.length)) return null;

  return (
    <ImageListItem sx={{ ...sx, "> img": { height: "inherit !important" } }}>
      <img
        src={vehicle.pictures[0].src}
        alt={
          vehicle.modell.marke.name +
          " " +
          vehicle.modell.name +
          " " +
          vehicle.version
        }
        loading="lazy"
      />
      <ImageListItemBar
        title={vehicle.modell.marke.name + " " + vehicle.modell.name}
        subtitle={vehicle.version}
      />
    </ImageListItem>
  );
};

interface IPipeline {}

const Pipeline: React.FC<IPipeline> = (): JSX.Element | null => {
  const {
    state: { entities, result },
  } = React.useContext(CatalogStore);

  if (!(entities && result.vehicle)) return null;

  let ids = cookies.get("carbando.history");
  const idsGone = ids.filter(
    (id: string) => result.vehicle && !result.vehicle.includes(id)
  );
  if (idsGone.length > 0) {
    ids = ids.filter((id: string) => !idsGone.includes(id));
    cookies.set("carbando.history", ids);
  }

  return (
    <HistoryPage
      {...{ entities, ids }}
      link={(id: string) => `/catalog/dt/${id}`}
    />
  );
};

export default Pipeline;
