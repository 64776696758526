import React from "react";
import { useIntl } from "react-intl";

import Chip from "../../ChipIndicator";

interface IAufbauIndicator {
  filter: (number | string)[];
  dispatch?: any;
}

const AufbauIndicator = ({
  filter = [],
  dispatch,
}: IAufbauIndicator): JSX.Element | null => {
  const { formatMessage: t } = useIntl();
  if (!filter.length) return null;

  const handleDelete = (aufbau: any) => {
    dispatch({
      type: "REMOVE_ID_FROM_FILTER",
      payload: { filter: "aufbau", id: aufbau },
    });
  };
  return (
    <>
      {filter.map((aufbau) => (
        <Chip
          id={aufbau}
          key={aufbau}
          label={t({
            id: `aufbau.${aufbau}.label`,
          })}
          onDelete={() => handleDelete(aufbau)}
        />
      ))}
    </>
  );
};

export default AufbauIndicator;
