import { TVehicle } from "types";

interface IFarbeReducer {
  vehicles: TVehicle[];
  filter: any;
}

const FarbeAussenReducer = ({ vehicles, filter }: IFarbeReducer) => {
  if (!filter.farbe_aussen?.length) return vehicles;
  return vehicles.filter(({ farbe_aussen: farbe }) => {
    return (
      farbe && filter.farbe_aussen && filter.farbe_aussen.includes(farbe.id)
    );
  });
};

export { FarbeAussenReducer };
