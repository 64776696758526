import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Box from "@mui/material/Box";

import { TCatalogEntities } from "types";

import List from "../../List";
import Filter from "../../Filter";

import Pipeline from "../Pipeline";

import FzartReducer from "./FzartReducer";
import FzartIndicator from "./FzartIndicator";

interface IFilter {
  entities: TCatalogEntities;
  value: number[];
  dispatchFilter: any;
}

export const FzartFilter: React.FC<IFilter> = ({
  entities = { fzart: { "1": { id: 1, name: "Option 1" } } },
  value = [],
  dispatchFilter = () => null,
}): JSX.Element | null => {
  const { formatMessage: t } = useIntl();

  if (!entities.fzart) return null;

  const items = Object.values(entities.fzart);

  const vehiclesCount = items.length;

  const setValueHandler = (value: any) => {
    // Check if all variants are selected
    const newValue = value.length === vehiclesCount ? [] : value;
    dispatchFilter({ type: "UPDATE_FILTER", payload: { fzart: newValue } });
  };

  const options = items.map(({ id, name }) => ({
    value: id,
    title: t({ id: `fzart.${id}.label` }, { defaultMessage: name }),
  }));

  const all = {
    title: t({ id: "common.alle", defaultMessage: "Alle" }),
  };

  const Component = (
    <Box width={1}>
      <List {...{ value, options, setValueHandler, all }} />
    </Box>
  );

  return (
    <Filter
      component={Component}
      title={
        <FormattedMessage id="filter.fzart.label" defaultMessage="Fzart" />
      }
    />
  );
};

const Container = () => {
  return (
    <Pipeline
      {...{
        type: "fzart",
        defaultValue: [],
        Component: FzartFilter,
        Reducer: FzartReducer,
        Indicator: FzartIndicator,
      }}
    />
  );
};

export default Container;
