import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";

import { TVehicle } from "types";
import { Tupel } from "components";

interface IEmissions {
  vehicle: TVehicle;
}

export const Verbrenner: React.FC<IEmissions> = ({ vehicle }): JSX.Element => (
  <>
    <Typography variant="h6">
      <FormattedMessage
        id="term.Kraftstoffverbrauch"
        defaultMessage="Kraftstoffverbrauch"
      />
    </Typography>
    <Tupel
      labelId="term.combined"
      label="kombiniert"
      float={vehicle.wltp_kombiniert}
      unit="l/100km"
      footnote={1}
      boxProps={{ fontWeight: "bold" }}
    />
    <Tupel
      labelId="term.innenstadt"
      label="Innenstadt"
      float={vehicle.wltp_langsam}
      unit="l/100km"
      footnote={1}
    />
    <Tupel
      labelId="term.stadtrand"
      label="Stadtrand"
      float={vehicle.wltp_mittel}
      unit="l/100km"
      footnote={1}
    />
    <Tupel
      labelId="term.landstrasse"
      label="Landstraße"
      float={vehicle.wltp_schnell}
      unit="l/100km"
      footnote={1}
    />
    <Tupel
      labelId="term.autobahn"
      label="Autobahn"
      float={vehicle.wltp_sehr_schnell}
      unit="l/100km"
      footnote={1}
    />
  </>
);

export default Verbrenner;
