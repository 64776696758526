import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Box from "@mui/material/Box";

import { TCatalogEntities } from "types";
import Pipeline from "../Pipeline";

import List from "../../List";
import Filter from "../../Filter";

import LocationReducer from "./LocationReducer";
import LocationIndicator from "./LocationIndicator";

interface IFilter {
  entities: TCatalogEntities;
  value: number[];
  dispatchFilter: any;
}

export const LocationFilter: React.FC<IFilter> = ({
  entities = {},
  value = [],
  dispatchFilter = () => null,
}): JSX.Element | null => {
  const { formatMessage: t } = useIntl();

  if (!entities.location) return null;

  const items = Object.values(entities.location);
  const itemsCount = items.length;

  const setValueHandler = (value: any) => {
    // Check if all variants are selected
    const newValue = value.length === itemsCount ? [] : value;
    dispatchFilter({ type: "UPDATE_FILTER", payload: { location: newValue } });
  };

  const options = items.map(({ id, name }) => ({
    value: id,
    title: name,
  }));

  const all = {
    title: t({ id: "common.alle", defaultMessage: "Alle" }),
  };

  const Component = (
    <Box width={1}>
      <List {...{ value, options, setValueHandler, all }} />
    </Box>
  );
  return (
    <Filter
      component={Component}
      title={
        <FormattedMessage
          id="filter.location.label"
          defaultMessage="Standort"
        />
      }
    />
  );
};

const Container = () => {
  return (
    <Pipeline
      {...{
        type: "location",
        defaultValue: [],
        Component: LocationFilter,
        Reducer: LocationReducer,
        Indicator: LocationIndicator,
      }}
    />
  );
};

export default Container;
