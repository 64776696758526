import { FormattedMessage } from "react-intl";
import { Controller } from "react-hook-form";

import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

interface IReactHookFormSelect {
  name: string;
  control: any;
  defaultValue?: boolean;
  disabled?: boolean;
  label?: string;
}

const ReactHookFormSwitch = ({
  name = "test",
  control = () => null,
  defaultValue = false,
  label,
  disabled = false,
}: IReactHookFormSelect): JSX.Element => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => {
        return (
          <FormControlLabel
            control={
              <Switch
                onBlur={onBlur} // notify when input is touched
                onChange={onChange} // send value to hook form
                checked={value}
                inputRef={ref}
                color={value ? "success" : "info"}
                disabled={disabled}
              />
            }
            label={label || <FormattedMessage id={`forms.${name}.label`} />}
          />
        );
      }}
    />
  );
};
export default ReactHookFormSwitch;
