import React, { useState, useEffect } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { extent } from "d3-array";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import MUISlider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";

import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";

import { CatalogStore, FilterStore } from "stores";

import Filter from "../../Filter";

import FinanceReducer from "./FinanceReducer";
import FinanceIndicator from "./FinanceIndicator";

export const FinanceFilter: React.FC = (): JSX.Element | null => {
  const [open, setOpen] = useState(false);
  const [ranges, setRanges]: [any, any] = useState();

  const {
    state: {
      entities: { vehicle = null },
    },
  } = React.useContext(CatalogStore);

  const {
    state: { filter },
    dispatch,
  } = React.useContext(FilterStore);

  React.useEffect(
    () => {
      dispatch({
        type: "ADD_REDUCER",
        payload: { key: "finance", value: FinanceReducer },
      });
      dispatch({
        type: "ADD_INDICATOR",
        payload: { key: "finance", value: FinanceIndicator },
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getDefaultValue = (range: any) => {
    const filter = {
      type: "none",
      price: range.has("preis") && range.get("preis"),
      classicCarLoan: !!range.has("credit"),
      balloonFinancing: !!range.has("balloon"),
      leasingMileage: !!range.has("leasing"),
      deposit: 0,
      mileage: range.has("mileage") && range.get("mileage")[0],
      rate: range.has("rate") && range.get("rate"),
      runTime: range.has("runtime") && range.get("runtime")[1],
    };

    return filter;
  };

  const widen = (input: [any, any], use_precision: number = 0) => {
    if (!(input && input.length === 2)) return [];
    if (isNaN(input[0]) || isNaN(input[0])) return [];

    const difference = input[1] - input[0];

    const precision =
      use_precision ||
      (difference <= 200 ? 10 : difference <= 1000 ? 50 : 1000);

    const widened = [];
    widened.push(Math.floor(input[0] / precision) * precision);
    widened.push(Math.ceil(input[1] / precision) * precision);
    return widened;
  };

  // Statistics
  React.useEffect(
    () => {
      if (!vehicle) return;
      const ranges = new Map();
      const vehicles = Object.values(vehicle);

      const preisRange = widen(
        extent(vehicles.map((e: any) => e.preis).filter((e: number) => !!e)),
        1000
      );
      ranges.set("preis", preisRange);

      const runtimeRange = widen(
        extent(
          vehicles
            .map((e: any) =>
              e.finance
                ? e.finance
                    .map((e: any) => e.runtime)
                    .filter((e: number) => !!e)
                : []
            )
            .flat()
        ),
        6
      );

      if (runtimeRange.length) {
        ranges.set("runtime", runtimeRange);

        const creditRange = widen(
          extent(
            vehicles
              .map((e: any) =>
                e.finance
                  ? e.finance
                      .map((e: any) => e.credit)
                      .filter((e: number) => !!e)
                  : []
              )
              .flat()
          )
        );
        creditRange.length && ranges.set("credit", creditRange);

        const leasingRange = widen(
          extent(
            vehicles
              .map((e: any) =>
                e.finance
                  ? e.finance
                      .map((e: any) =>
                        e.leasing
                          ? e.leasing
                              .map((e: any) => e[1])
                              .filter((e: number) => !!e)
                          : []
                      )
                      .flat()
                  : []
              )
              .flat()
          )
        );
        leasingRange.length && ranges.set("leasing", leasingRange);

        const balloonRange = widen(
          extent(
            vehicles
              .map((e: any) =>
                e.finance
                  ? e.finance
                      .map((e: any) =>
                        e.balloon
                          ? e.balloon
                              .map((e: any) => e[1])
                              .filter((e: number) => !!e)
                          : []
                      )
                      .flat()
                  : []
              )
              .flat()
          )
        );
        balloonRange.length && ranges.set("balloon", balloonRange);
        const REACT_APP_MINIMUM_RATE = process.env.REACT_APP_MINIMUM_RATE
          ? parseInt(process.env.REACT_APP_MINIMUM_RATE)
          : 0;

        const rates = [
          REACT_APP_MINIMUM_RATE,
          creditRange,
          leasingRange,
          balloonRange,
        ]
          .flat()
          .filter((e: number) => !!e);

        const rateRange = rates.length ? extent(rates) : [];

        rateRange.length && ranges.set("rate", rateRange);

        const mileageRange = widen(
          extent(
            vehicles
              .map((e: any) =>
                e.finance
                  ? e.finance
                      .map((e: any) => {
                        return [
                          e.leasing
                            ? e.leasing
                                .map((e: any) => e[0])
                                .filter((e: number) => !!e)
                            : [],
                          e.balloon
                            ? e.balloon
                                .map((e: any) => e[0])
                                .filter((e: number) => !!e)
                            : [],
                        ].flat();
                      })
                      .flat()
                  : []
              )
              .flat()
          ),
          2000
        );
        mileageRange.length && ranges.set("mileage", mileageRange);
      }
      setRanges(ranges);
      dispatch({
        type: "INIT_FILTER",
        payload: {
          finance: getDefaultValue(ranges),
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vehicle]
  );

  if (!filter.finance) return null;

  const { finance: value } = filter;

  const setValueHandler = (value: any) => {
    dispatch({ type: "UPDATE_FILTER", payload: { finance: value } });
  };

  const submit = (value: any) => {
    setValueHandler(value);
  };

  // const reset = () => {
  //   setValueHandler(getDefaultValue(ranges));
  // };

  const changeValue = (what: any, newValue: any) => {
    const newValues = { ...value, [what]: newValue };

    const resetter = !(
      newValues.classicCarLoan ||
      newValues.balloonFinancing ||
      newValues.leasingMileage
    )
      ? {
          classicCarLoan: true,
          balloonFinancing: true,
          leasingMileage: true,
        }
      : {};
    submit({ ...newValues, ...resetter });
  };
  if (!ranges) return null;

  return (
    <Box width={1}>
      <Box mb={2}>
        <Type value={value.type} {...{ changeValue, ranges }} />
      </Box>

      <Collapse in={value.type === "buy"}>
        <Box mb={2}>
          <Price
            name="price"
            min={ranges.get("preis")[0]}
            max={ranges.get("preis")[1]}
            value={value.price}
            changeValue={changeValue}
          />
        </Box>
      </Collapse>
      {ranges.has("runtime") && (
        <Collapse in={"lease" === value.type}>
          <Box mb={2}>
            <Rate
              name="rate"
              step={
                ranges.get("rate")[1] <= 200
                  ? 10
                  : ranges.get("rate")[1] <= 500
                    ? 50
                    : 100
              }
              min={ranges.get("rate")[0]}
              max={ranges.get("rate")[1]}
              value={value.rate}
              changeValue={changeValue}
            />
          </Box>
          <Opener
            toggleOpen={setOpen}
            open={open}
            title={
              <FormattedMessage id="filter.financial.refineSearch.label" />
            }
          />
          <Collapse in={open}>
            <Box>
              <Box mb={2}>
                <Deposit
                  name="deposit"
                  value={value.deposit}
                  max={ranges.get("preis")[1]}
                  changeValue={changeValue}
                />
              </Box>
              <RunTime
                name="runTime"
                min={ranges.get("runtime")[0]}
                max={ranges.get("runtime")[1]}
                value={value.runTime}
                changeValue={changeValue}
              />
              <Box mb={2}>
                <Mileage
                  value={value.mileage}
                  min={ranges.get("mileage")[0]}
                  step={ranges.get("rate")[1] > 10000 ? 1000 : 100}
                  max={ranges.get("mileage")[1]}
                  changeValue={changeValue}
                />
              </Box>
            </Box>
            <Typography variant="h6" color="secondary">
              <FormattedMessage id="filter.financial.rateType.headline.label" />
            </Typography>
            {ranges.has("credit") && (
              <ClassicCarLoan
                value={value.classicCarLoan}
                changeValue={changeValue}
              />
            )}
            {ranges.has("balloon") && (
              <BalloonFinancing
                value={value.balloonFinancing}
                changeValue={changeValue}
              />
            )}

            {ranges.has("leasing") && (
              <LeasingMileage
                value={value.leasingMileage}
                changeValue={changeValue}
              />
            )}
          </Collapse>
        </Collapse>
      )}
    </Box>
  );
};

const Slider = (props: any): JSX.Element => (
  <MUISlider
    {...props}
    sx={{
      "& .MuiSlider-markLabel[data-index='0']": {
        transform: "translateX(10%)",
      },
      "& .MuiSlider-markLabel[data-index='1']": {
        transform: "translateX(-110%)",
      },
    }}
  />
);

interface IOpener {
  toggleOpen: any;
  open: any;
  title: any;
}

const Opener: React.FC<IOpener> = ({ toggleOpen, open, title }) => (
  <Box
    display="flex"
    borderBottom={1}
    my={3}
    onClick={() => toggleOpen(!open)}
    className="cursorHand"
  >
    <Box flexGrow={1}>
      <Typography variant="body2" color="secondary">
        {title}
      </Typography>
    </Box>
    <Box>
      {open ? (
        <ArrowDropUp fontSize="small" />
      ) : (
        <ArrowDropDown fontSize="small" />
      )}
    </Box>
  </Box>
);

// interface IReset {
//   resetHandler: any;
// }
// const Reset: React.FC<IReset> = ({ resetHandler }) => (
//   <Button
//     className="cursorHand underlined"
//     color="primary"
//     onClick={() => resetHandler()}
//   >
//     <FormattedMessage id="filter.financial.action.reset.label" />
//   </Button>
// );

interface IValue {
  value: any;
  name?: string;
  changeValue?: any;
}

interface ISlider extends IValue {
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
}
export const Rate: React.FC<ISlider> = ({
  min,
  max,
  name,
  step,
  value,
  changeValue,
}) => (
  <CurrencySlider
    changeValue={changeValue}
    max={max}
    min={min}
    name={name}
    step={step}
    title={<FormattedMessage id="filter.financial.monthlyRate.title.label" />}
    value={value}
  />
);

export const Price: React.FC<ISlider> = ({
  min,
  name,
  max,
  step = 1000,
  value,
  changeValue,
}) => (
  <CurrencySlider
    name={name}
    changeValue={changeValue}
    max={max}
    min={min}
    step={step}
    title={
      <FormattedMessage id="filter.financial.type.options.purchase.label" />
    }
    value={value}
  />
);
export const Deposit: React.FC<ISlider> = ({
  min = 0,
  step,
  name,
  max,
  value,
  changeValue,
  disabled = false,
}) => (
  <CurrencySlider
    name={name}
    title={<FormattedMessage id="filter.financial.deposit.title.label" />}
    changeValue={changeValue}
    max={max}
    min={min}
    step={step}
    value={value}
    disabled={disabled}
  />
);

const ClassicCarLoan: React.FC<IValue> = ({ changeValue, value }) => (
  <Bool
    changeValue={changeValue}
    label={
      <FormattedMessage id="filter.financial.rateType.options.classicCarLoan.label" />
    }
    name="classicCarLoan"
    value={value}
  />
);
const BalloonFinancing: React.FC<IValue> = ({ changeValue, value }) => (
  <Bool
    changeValue={changeValue}
    label={
      <FormattedMessage id="filter.financial.rateType.options.balloonFinancing.label" />
    }
    name="balloonFinancing"
    value={value}
  />
);

const LeasingMileage: React.FC<IValue> = ({ changeValue, value }) => (
  <Bool
    changeValue={changeValue}
    label={
      <FormattedMessage id="filter.financial.rateType.options.leasingMileage.label" />
    }
    name="leasingMileage"
    value={value}
  />
);

interface IBool extends IValue {
  label: any;
  name: any;
  disabled?: boolean;
}

const Bool: React.FC<IBool> = ({
  value,
  changeValue,
  label,
  name,
  disabled = false,
}) => (
  <FormGroup row>
    <FormControlLabel
      disabled={disabled}
      control={
        <Checkbox
          checked={value}
          onChange={() => changeValue(name, !value)}
          value={value}
          color="primary"
        />
      }
      label={label}
    />
  </FormGroup>
);

export const RunTime: React.FC<ISlider> = ({
  name = "runTime",
  value = 36,
  changeValue,
  min = 12,
  max = 48,
  step = 6,
  disabled = false,
}) => {
  const [localValue, setLocalValue] = useState(36);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const submit = (_element: any, value: any) => {
    setLocalValue(value);
    changeValue(name, localValue);
  };

  const handleChange = (_element: any, value: any) => {
    setLocalValue(value);
  };

  const marks = [
    { value: min, label: min },
    { value: max, label: max },
  ];

  return (
    <Box className="slider" sx={{ opacity: disabled ? 0.3 : 1 }}>
      <Typography variant="h6" color="secondary">
        <FormattedMessage id="filter.financial.runningTime.title.label" />
      </Typography>
      <Typography align="right" variant="body2" color="secondary">
        <FormattedMessage id="common.to" defaultMessage="bis" />
        &nbsp;
        {localValue}
        &nbsp;
        <FormattedMessage id="common.months" defaultMessage="Monate" />
      </Typography>
      {min < max && (
        <Box sx={{ px: 1 }}>
          <Slider
            marks={marks}
            max={max}
            min={min}
            onChange={handleChange}
            onChangeCommitted={submit}
            step={step || getStep(max - min)}
            value={localValue}
            disabled={disabled}
          />
        </Box>
      )}
    </Box>
  );
};

export const Mileage: React.FC<ISlider> = ({
  changeValue,
  name = "mileage",
  max = 50000,
  min = 10000,
  step,
  value = 36,
  disabled = false,
}) => {
  const [localValue, setLocalValue] = useState(36);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const submit = (element: any, value: any) => {
    setLocalValue(value);
    changeValue(name, localValue);
  };

  const handleChange = (element: any, value: any) => {
    setLocalValue(value);
  };

  const marks = [
    { value: min, label: <FormattedNumber unit="kilometer" value={min} /> },
    { value: max, label: <FormattedNumber unit="kilometer" value={max} /> },
  ];

  return (
    <Box className="slider" sx={{ opacity: disabled ? 0.3 : 1 }}>
      <Typography variant="h6" color="secondary">
        <FormattedMessage id="filter.financial.mileage.title.label" />
      </Typography>
      <Typography align="right" variant="body2" color="secondary">
        <FormattedMessage id="common.mindestens" defaultMessage="mindestens" />
        &nbsp;
        <FormattedNumber value={localValue} />
        &nbsp;km
      </Typography>
      {min < max && (
        <Box sx={{ px: 1 }}>
          <Slider
            marks={marks}
            max={max}
            min={min}
            onChange={handleChange}
            onChangeCommitted={submit}
            step={step || getStep(max - min)}
            disabled={disabled}
            value={localValue}
          />
        </Box>
      )}
    </Box>
  );
};

interface ICurrencySlider {
  changeValue: any;
  name: any;
  max: any;
  min: any;
  step?: any;
  title: any;
  value: any;
  disabled?: boolean;
}
const CurrencySlider: React.FC<ICurrencySlider> = ({
  changeValue,
  name,
  max,
  min,
  step,
  title,
  value,
  disabled = false,
}) => {
  const [localValue, setLocalValue] = useState(0);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const submit = (_element: any, value: any) => {
    setLocalValue(value);
    changeValue(name, localValue);
  };

  const handleChange = (_element: any, value: any) => {
    setLocalValue(value);
  };

  const marks = [
    {
      value: min,
      label: <EURO value={min} />,
    },
    {
      value: max,
      label: <EURO value={max} />,
    },
  ];

  return (
    <Box className="slider" sx={{ opacity: disabled ? 0.3 : 1 }}>
      {title && (
        <Typography variant="h6" color="secondary">
          {title}
        </Typography>
      )}
      <Typography align="right" variant="body2" color="secondary">
        {Array.isArray(localValue) && <EURO value={localValue[0]} />}
        &nbsp;
        <FormattedMessage id="common.to" defaultMessage="bis" />
        &nbsp;
        <EURO value={Array.isArray(localValue) ? localValue[1] : localValue} />
      </Typography>
      <Box sx={{ px: 1 }}>
        <Slider
          marks={marks}
          max={max}
          min={min}
          onChange={handleChange}
          onChangeCommitted={submit}
          step={step || getStep(max - min)}
          value={localValue}
        />
      </Box>
    </Box>
  );
};

const getStep = (num: number): number => {
  const zeros = getZeros(num);
  const step =
    Math.round(num / (2 * Math.pow(10, zeros))) * Math.pow(10, zeros - 1);
  return step;
};

const getZeros = (num: number): number => {
  let zeros = -1;

  do {
    num = num / 10;
    ++zeros;
  } while (num > 1);
  return zeros;
};

interface IType extends IValue {
  ranges?: any;
}

const Type: React.FC<IType> = ({ value, changeValue, ranges = {} }) => {
  const submit = (element: any, value: any) => {
    changeValue("type", value);
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup name="type" value={value} onChange={submit}>
        <FormControlLabel
          control={<Radio color="primary" />}
          value="none"
          label={
            <FormattedMessage
              id="filter.financial.type.options.none.label"
              defaultMessage="Alle"
            />
          }
        />
        <FormControlLabel
          control={<Radio color="primary" />}
          value="buy"
          label={
            <FormattedMessage
              id="filter.financial.type.options.purchase.label"
              defaultMessage="Kaufpreis"
            />
          }
        />
        {ranges.has("runtime") && (
          <FormControlLabel
            control={<Radio color="primary" />}
            value="lease"
            label={
              <FormattedMessage id="filter.financial.type.options.monthly.label" />
            }
          />
        )}
      </RadioGroup>
    </FormControl>
  );
};

interface IEURO {
  value: number;
}
const EURO: React.FC<IEURO> = ({ value }): JSX.Element => (
  <FormattedNumber
    minimumFractionDigits={0}
    maximumFractionDigits={0}
    value={value}
    // eslint-disable-next-line react/style-prop-object
    style="currency"
    currency="EUR"
  />
);

const FinanceFilterPanel = (props: any) => (
  <Filter
    {...{
      title: (
        <FormattedMessage
          id="filter.financial.title.label"
          defaultMessage="price / rate"
        />
      ),
      component: <FinanceFilter {...props} />,
    }}
  />
);

export default FinanceFilterPanel;
