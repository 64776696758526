import { FormattedMessage } from "react-intl";
import { Controller } from "react-hook-form";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

interface IReactHookFormCheckbox {
  name: string;
  control: any;
  defaultValue?: boolean;
  label?: any;
  inputProps?: any;
  labelId?: string;
  disabled?: boolean;
}

const ReactHookFormCheckbox = ({
  name = "test",
  control = () => null,
  defaultValue = false,
  label,
  inputProps = {},
  labelId,
  disabled = false,
}: IReactHookFormCheckbox): JSX.Element => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              onBlur={onBlur} // notify when input is touched
              onChange={onChange} // send value to hook form
              checked={value}
              inputRef={ref}
              color={value ? "success" : "info"}
              inputProps={{
                ...inputProps,
                "data-testid": `Checkbox-${name}`,
              }}
            />
          }
          label={
            label ? (
              label
            ) : (
              <FormattedMessage
                id={labelId ? labelId : `forms.${name}.label`}
              />
            )
          }
        />
      )}
    />
  );
};
export default ReactHookFormCheckbox;
