import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Box from "@mui/material/Box";
import { TCatalogEntities } from "types";

import List from "../../List";
import Filter from "../../Filter";

import KraftstoffReducer from "./KraftstoffReducer";
import KraftstoffIndicator from "./KraftstoffIndicator";

import Pipeline from "../Pipeline";

interface IFilter {
  entities: TCatalogEntities;
  value: number[];
  dispatchFilter: any;
}

export const KraftstoffFilter: React.FC<IFilter> = ({
  entities = { kraftstoff: { "1": { id: 1, name: "Option 1" } } },
  value = [],
  dispatchFilter = () => null,
}): JSX.Element | null => {
  const { formatMessage: t } = useIntl();

  if (!entities.kraftstoff) return null;

  const items = Object.values(entities.kraftstoff);

  const itemCount = items.length;

  const setValueHandler = (value: any) => {
    // Check if all variants are selected
    const newValue = value.length === itemCount ? [] : value;
    dispatchFilter({
      type: "UPDATE_FILTER",
      payload: { kraftstoff: newValue },
    });
  };

  const options = items.map(({ id, name }) => ({
    value: id,
    title: t({ id: `kraftstoff.${id}.label` }, { defaultMessage: name }),
  }));

  const all = {
    title: t({ id: "common.alle", defaultMessage: "Alle" }),
  };

  const Component = (
    <Box width={1}>
      <List {...{ value, options, setValueHandler, all }} />
    </Box>
  );

  return (
    <Filter
      component={Component}
      open={false}
      title={
        <FormattedMessage id="filter.kraftstoff.label" defaultMessage="Fzart" />
      }
    />
  );
};

const Container = () => {
  return (
    <Pipeline
      {...{
        type: "kraftstoff",
        defaultValue: [],
        Component: KraftstoffFilter,
        Reducer: KraftstoffReducer,
        Indicator: KraftstoffIndicator,
      }}
    />
  );
};

export default Container;
