import React from "react";

import { FilterStore } from "stores";

import Box from "@mui/material/Box";

import ResetFilter from "./ResetFilter";

const Indicator: React.FC = (): JSX.Element | null => {
  const {
    state: { filter, indicator },
    dispatch,
  } = React.useContext(FilterStore);
  if (indicator.size === 0) return null;
  return (
    <Box
      sx={{
        position: "sticky",
        mb: 2,
        top: -1,
        backgroundColor: "#FFFFFFEE",
        minHeight: 39,
      }}
    >
      <Box>
        {Array.from(indicator, ([key, f]: [string | number, any]) => (
          <span key={key}>{f({ filter: filter[key], dispatch })}</span>
        ))}
      </Box>
      <ResetFilter sx={{ position: "absolute", top: 2, right: 2 }} />
    </Box>
  );
};

export default Indicator;
