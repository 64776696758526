import React from "react";
import { useNavigate } from "react-router-dom";
import { rollups, extent, min, sum } from "d3-array";

import { TVehicle } from "types";

import { VehicleStore, FilterStore } from "stores";
import ModelleCard from "./ModelleCard";
import ModellGruppeCard from "../ModellGruppe/ModellGruppeCard";
import Box from "@mui/material/Box";

import Header from "./Header";
import { EmptySearch } from "../";

const Modelle: React.FC = (): JSX.Element | null => {
  const { state: vehicles } = React.useContext(VehicleStore);
  const { state: filters, dispatch: dispatchFilter } =
    React.useContext(FilterStore);
  const sortOrder = filters.filter.finance?.type === "lease" ? "rate" : "preis";

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  if (!vehicles) return null;
  if (vehicles.length === 0) return <EmptySearch />;

  const groups = groupVehicles(vehicles).sort((a: any, b: any) => {
    return b[1][sortOrder] > a[1][sortOrder] ? -1 : 1;
  });

  const setModell = (id: any) => {
    const payload = {
      type: "UPDATE_FILTER",
      payload: { modell: [id] },
    };
    dispatchFilter(payload);
    navigate("/catalog/mo/" + id);
  };

  const setModellGroup = (id: any) => {
    const payload = {
      type: "UPDATE_FILTER",
      payload: { modell_gruppe: [id] },
    };
    dispatchFilter(payload);
    navigate("/catalog/mg/" + id);
  };

  const detail = (id: any) => {
    navigate("/catalog/dt/" + id);
  };
  return (
    <>
      <Header />
      <Box
        sx={{
          display: "grid",
          columnGap: { xs: 1, sm: 2, md: 3 },
          rowGap: { xs: 2, md: 3, lg: 4 },
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          },
        }}
      >
        {groups.map(([ndx, group]: [string, any]) => {
          const Card =
            group.groupType === "gruppe" ? ModellGruppeCard : ModelleCard;
          const handler =
            group.vorrat === 1
              ? detail
              : group.groupType === "gruppe"
                ? setModellGroup
                : setModell;
          const id = group.vorrat === 1 ? group.id : group.groupId;

          return (
            <Card
              key={id}
              data={group}
              onClick={() => handler(id)}
              paperProps={{
                sx: {
                  borderRadius: 1,
                  backgroundColor: "grey.300",
                  ":hover": {
                    borderColor: "grey.800",
                    backgroundColor: "grey.300",
                    cursor: "pointer",
                  },
                },
              }}
            />
          );
        })}
      </Box>
    </>
  );
};

const groupVehicles = (vehicles: TVehicle[]): any => {
  const preparedVehicles = vehicles.map((vehicle: TVehicle) => ({
    ...vehicle,
    groupType: vehicle.modell.modell_gruppe ? "gruppe" : "modell",
    groupId: vehicle.modell.modell_gruppe
      ? vehicle.modell.modell_gruppe.id
      : vehicle.modell.id,
    group: vehicle.modell.modell_gruppe
      ? "gruppe_" + vehicle.modell.modell_gruppe.id
      : "modell_" + vehicle.modell.id,
  }));

  const groupedVehicles = rollups(
    preparedVehicles,
    (v: any) => ({
      picture:
        v[0].pictures.length > 0 && v[0].pictures[0].src
          ? v[0].pictures[0].src
          : "",
      modell: v[0].modell,
      id: v[0].wgnr,
      vorrat: sum(v, (d: any) => 1),
      rate: min(v, (d: any) => d.rate),
      preis: min(v, (d: any) => d.preis),
      groupType: v[0].groupType,
      groupId: v[0].groupId,
      group: v[0].group,
      co2_effizienzklasse: extent(v, ({ co2_effizienzklasse }: any) =>
        check(co2_effizienzklasse)
      ),
      co2_emissionen: extent(v, (d: any) => d.co2_emissionen),
      verbrauch_kombiniert: extent(v, ({ verbrauch_kombiniert }: any) =>
        check(verbrauch_kombiniert)
      ),
      stromverbrauch_kombiniert: extent(
        v,
        ({ stromverbrauch_kombiniert }: any) => check(stromverbrauch_kombiniert)
      ),
    }),
    (d: any) => d.group
  );
  return groupedVehicles;
};

const check = (d: any) => (d ? d : null);

export default Modelle;
