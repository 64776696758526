import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import {
  Header,
  Footer,
  Breadcrumb,
  CookieWall,
  ContactSpeedDial,
  HistoryPage,
} from "containers";
import { Home, Catalog, Error404, Checkout } from "pages";
import { ExtMd } from "components";

const App = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Header />
      <Breadcrumb />
      <Box sx={{ flexGrow: 1, background: "grey.200" }}>
        <Container maxWidth={"xl"} sx={{ p: { xs: 0, sm: 1 } }}>
          <Routes>
            <Route index element={<Navigate replace to="catalog" />} />
            <Route path="/" element={<Home />} />
            <Route path="/catalog/*" element={<Catalog />} />
            <Route path="/history" element={<HistoryPage />} />
            <Route path="/checkout/*" element={<Checkout />} />
            <Route path="/withdrawal" element={<ExtMd topic="withdrawal" />} />
            <Route path="/imprint" element={<ExtMd topic="imprint" />} />
            <Route path="/privacy" element={<ExtMd topic="privacy" />} />
            <Route path="/agb" element={<ExtMd topic="agb" />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </Container>
      </Box>
      <Footer />
      <CookieWall />
      <ContactSpeedDial sx={{ position: "fixed", right: 10, bottom: 10 }} />
    </Box>
  );
};

export default App;
