import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import { LanguageSwitch } from "intl";

const Header = (): JSX.Element => {
  return (
    <Box
      sx={{
        height: 100,
        color: "header.color",
        bgcolor: "header.backgroundColor",
      }}
    >
      <Container maxWidth={"xl"}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            justifyContent: "space-between",
            p: 1,
          }}
        >
          <Logo
            cdn={process.env.REACT_APP_CDN_SERVER}
            customer={process.env.REACT_APP_SHOP_ID}
            filename="header-1"
            fallback
          />
          <LanguageSwitch />
        </Box>
      </Container>
    </Box>
  );
};

interface ILogo {
  cdn?: string;
  customer?: string;
  filename?: string;
  alt?: string;
  fallback?: boolean;
}

const Logo = ({
  cdn = "https://cdn.carbando.eu",
  customer = "develop",
  filename = "header-1",
  alt = "logo",
  fallback = false,
}: ILogo): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        maxWidth: "50%",
        ":hover": {
          cursor: "pointer",
        },
      }}
      onClick={() => navigate("/")}
    >
      <img
        height="80"
        width="100%"
        alt={alt}
        src={`${cdn}/${customer}/${filename}.svg`}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          fallback
            ? (currentTarget.src = `${cdn}/${customer}/${filename}.png`)
            : currentTarget.remove();
        }}
      />
    </Box>
  );
};

export default Header;
