import { createTheme } from "@mui/material/styles";

const getCustomerTheme = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_CDN_SERVER}/${process.env.REACT_APP_SHOP_ID}/theme.json`
  );
  if (response.status !== 200) {
    return createTheme();
  }
  let data = await response.json();
  return createTheme({ ...data });
};

export default getCustomerTheme;
