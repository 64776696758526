import React from "react";

import { CatalogStore } from "stores";
import { TCatalogEntities } from "types";

import Chip from "../../ChipIndicator";

interface ILocationIndicator {
  filter: number[];
  dispatch: any;
  entities: TCatalogEntities;
}

export const LocationIndicator = ({
  entities = { location: {} },
  filter = [],
  dispatch = () => null,
}: ILocationIndicator): JSX.Element | null => {
  if (!(filter.length && entities.location)) return null;

  const handleDelete = (location: any) => {
    dispatch({
      type: "REMOVE_ID_FROM_FILTER",
      payload: { filter: "location", id: location },
    });
  };

  const locations = entities.location;
  return (
    <>
      {filter.map((id) => (
        <Chip
          id={id}
          key={id}
          label={locations[id].name}
          onDelete={() => handleDelete(id)}
        />
      ))}
    </>
  );
};

interface IIndicatorContainer {
  filter: number[];
  dispatch: any;
}

const Container: React.FC<IIndicatorContainer> = ({
  filter = [],
  dispatch = () => null,
}) => {
  const {
    state: { entities },
  } = React.useContext(CatalogStore);
  return (
    <LocationIndicator
      {...{
        filter,
        dispatch,
        entities,
      }}
    />
  );
};

export default Container;
