import { TVehicles as vehicles } from "./catalog";
import { TVehicle } from "types";

export const getVehicleByKraftstoff = (kraftstoff: string): TVehicle | any => {
  return (
    vehicles.find(
      (vehicle: TVehicle) => vehicle.kraftstoff?.name === kraftstoff
    ) || {}
  );
};
export const KraftstoffBenzin = getVehicleByKraftstoff("Benzin");
export const KraftstoffDiesel = getVehicleByKraftstoff("Diesel");
export const KraftstoffElektro = getVehicleByKraftstoff("Elektro");
export const KraftstoffAutogas = getVehicleByKraftstoff("Autogas/LPG");
export const KraftstoffWasserstoff = getVehicleByKraftstoff("Wasserstoff");
export const KraftstoffSuperE5 = getVehicleByKraftstoff("Super E5");
export const KraftstoffSuperE10_95 = getVehicleByKraftstoff("Super E10 95");

const getHybridVehicleByKraftstoff = (kraftstoff: string): TVehicle | any => {
  return (
    vehicles.find(
      (vehicle: TVehicle) =>
        vehicle.kraftstoff?.name === kraftstoff &&
        vehicle.wltp_co2_emissionen_entladen &&
        vehicle.wltp_co2_emissionen_entladen > 0
    ) || {}
  );
};
export const KraftstoffPlugInHybridBenzin =
  getHybridVehicleByKraftstoff("Hybrid-Benzin");
export const KraftstoffPlugInHybridDiesel =
  getHybridVehicleByKraftstoff("Hybrid-Diesel");

const getMildHybridVehicleByKraftstoff = (
  kraftstoff: string
): TVehicle | any => {
  return (
    vehicles.find(
      (vehicle: TVehicle) =>
        vehicle.kraftstoff?.name === kraftstoff &&
        !vehicle.wltp_co2_emissionen_entladen
    ) || {}
  );
};
export const KraftstoffMildHybridBenzin =
  getMildHybridVehicleByKraftstoff("Hybrid-Benzin");
export const KraftstoffMildHybridDiesel =
  getMildHybridVehicleByKraftstoff("Hybrid-Diesel");

export const getVehicleByFZArt = (fzart: string): TVehicle | any => {
  return (
    vehicles.find((vehicle: TVehicle) => vehicle.fzart?.name === fzart) || {}
  );
};
export const FZArtNeuwagen = getVehicleByFZArt("Neuwagen");
export const FZArtTageszulassung = getVehicleByFZArt("Tageszulassung");
export const FZArtVorfuehrwagen = getVehicleByFZArt("Vorführwagen");
export const FZArtGebrauchtwagen = getVehicleByFZArt("Gebrauchtwagen");

export const EmissionNull: any = vehicles.find(
  (vehicle: TVehicle) => vehicle.co2_emissionen === null
);
