import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Box from "@mui/material/Box";
import { CatalogStore, FilterStore } from "stores";
import { TCatalogEntities } from "types";

import List from "../../List";
import Filter from "../../Filter";

import Reducer from "./GetriebeReducer";
import Indicator from "./GetriebeIndicator";

const Pipeline: React.FC = (): JSX.Element | null => {
  const {
    state: { entities },
  } = React.useContext(CatalogStore);

  const {
    state: {
      filter: { fzart: value = [] },
    },
    dispatch: dispatchFilter,
  } = React.useContext(FilterStore);

  React.useEffect(
    () => {
      dispatchFilter({
        type: "ADD_REDUCER",
        payload: { key: "getriebe", value: Reducer },
      });
      dispatchFilter({
        type: "ADD_INDICATOR",
        payload: { key: "getriebe", value: Indicator },
      });
      dispatchFilter({
        type: "INIT_FILTER",
        payload: { getriebe: [] },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!entities) return null;

  return <GetriebeFilter {...{ entities, value, dispatchFilter }} />;
};

interface IFilter {
  entities: TCatalogEntities;
  value: number[];
  dispatchFilter: any;
}

export const GetriebeFilter: React.FC<IFilter> = ({
  entities = { getriebe: { "1": { id: 1, name: "Option 1" } } },
  value = [],
  dispatchFilter = () => null,
}): JSX.Element | null => {
  const { formatMessage: t } = useIntl();

  if (!entities.getriebe) return null;

  const items = Object.values(entities.getriebe);

  const vehiclesCount = items.length;

  const setValueHandler = (value: any) => {
    // Check if all variants are selected
    const newValue = value.length === vehiclesCount ? [] : value;
    dispatchFilter({ type: "UPDATE_FILTER", payload: { getriebe: newValue } });
  };
  const options = items.map(({ id, name }) => ({
    value: id,
    title: t({ id: `getriebe.${id}.label` }, { defaultMessage: name }),
  }));

  const all = {
    title: t({ id: "common.alle", defaultMessage: "Alle" }),
  };

  const Component = (
    <Box width={1}>
      <List {...{ value, options, setValueHandler, all }} />
    </Box>
  );

  return (
    <Filter
      component={Component}
      title={
        <FormattedMessage
          id="filter.getriebe.label"
          defaultMessage="Getriebe"
        />
      }
    />
  );
};

export default Pipeline;
