import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import SpeedDialIcon from "@mui/icons-material/WhatsApp";
import CloseIcon from "@mui/icons-material/Close";

const Content: React.FC = (): JSX.Element => (
  <Grid container spacing={2}>
    <Grid item xs={12} sx={{ textAlign: "center" }}>
      <a
        aria-label="Chat on WhatsApp"
        href="https://wa.me/+4917696310860?text=Hallo"
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt="Chat on WhatsApp"
          src="https://cdn.carbando.eu/images/WhatsAppButtonGreenLarge.svg"
        />
      </a>
    </Grid>
  </Grid>
);

interface IForm {
  closeDialog?: any;
}
const Form: React.FC<IForm> = ({ closeDialog }): JSX.Element => {
  return <Content />;
};

interface IOverlay {
  open: boolean;
  closeOverlay?: any;
}
const Overlay: React.FC<IOverlay> = ({ closeOverlay, open = false }) => {
  const closeDialog = () => {
    closeOverlay && closeOverlay();
  };

  return (
    <Dialog open={open} onClose={() => closeDialog()}>
      <IconButton
        aria-label="delete"
        size="small"
        sx={{ position: "absolute", right: 2, top: 2 }}
        onClick={() => closeDialog()}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>
        Ich möchte per WhatsApp mit Ihnen in Kontakt treten.
      </DialogTitle>
      <DialogContent>
        <Form closeDialog={() => closeDialog()} />
      </DialogContent>
    </Dialog>
  );
};

const module = {
  speedDialIcon: <SpeedDialIcon />,
  overlay: Overlay,
};

export default module;
