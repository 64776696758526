import React from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";

import ListItemButton from "@mui/material/ListItemButton";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";

interface IListFilter {
  value: any;
  options: any;
  all?: {
    title: string;
    caption?: number | string;
  };
  setValueHandler: any;
  forceChecked?: boolean;
  color?: string;
}

interface IOption {
  title: any;
  caption: any;
  value: any;
  disabled: any;
}

interface ICollapsedListFilter {
  label: string;
}

export const CollapsedListFilter: React.FC<ICollapsedListFilter> = ({
  label,
  children,
}): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  return (
    <List>
      <ListItemButton disableRipple onClick={() => setOpen(!open)}>
        <ListItemText primary={label} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout={0} unmountOnExit>
        {children}
      </Collapse>
    </List>
  );
};

const ListFilter: React.FC<IListFilter> = ({
  value,
  options,
  all,
  forceChecked = false,
  setValueHandler: setValue,
  color = "inherit",
}): JSX.Element => {
  const handleToggle = (optionValue: any) => () => {
    const currentIndex = value.indexOf(optionValue);
    const newChecked = [...value];

    if (currentIndex === -1) {
      newChecked.push(optionValue);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setValue(newChecked);
  };
  const selectAll = () => setValue([]);
  return (
    <React.Fragment>
      {all && (
        <List>
          <ListItem key="all" role={undefined} dense button onClick={selectAll}>
            <ListItemIcon>
              <Checkbox
                color="primary"
                style={{ color }}
                edge="start"
                checked={!value.length}
                tabIndex={-1}
                inputProps={{ "aria-labelledby": "checkbox-list-label-all" }}
              />
            </ListItemIcon>
            <ListItemText
              id="checkbox-list-label-all"
              primary={all.title}
              primaryTypographyProps={{
                variant: "body1",
                component: "span",
                sx: { textTransform: "capitalize" },
              }}
              secondary={all.caption}
              secondaryTypographyProps={{
                variant: "body2",
                component: "span",
                align: "right",
              }}
            />
          </ListItem>
          <Divider />
        </List>
      )}
      <List dense className="options">
        {options
          .sort((a: { title: string }, b: { title: string }) =>
            b.title > a.title ? -1 : 1
          )
          .map(
            ({
              title,
              caption,
              value: optionValue,
              disabled = false,
            }: IOption) => {
              const labelId = `checkbox-list-label-${optionValue}`;
              return (
                <ListItem
                  key={optionValue}
                  role={undefined}
                  dense
                  button
                  disableRipple
                  onClick={handleToggle(optionValue)}
                  disabled={disabled}
                >
                  <ListItemIcon sx={{ minWidth: "inherit" }}>
                    <Checkbox
                      color="primary"
                      edge="start"
                      style={{
                        color:
                          value.indexOf(optionValue) !== -1 ? undefined : color,
                      }}
                      checked={
                        value.indexOf(optionValue) !== -1 || forceChecked
                      }
                      tabIndex={-1}
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    className="list-item-text"
                    id={labelId}
                    primary={title}
                    primaryTypographyProps={{ variant: "body1" }}
                    secondary={caption}
                    secondaryTypographyProps={{
                      variant: "body2",
                      align: "right",
                    }}
                  />
                </ListItem>
              );
            }
          )}
      </List>
    </React.Fragment>
  );
};

export default ListFilter;
