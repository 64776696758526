import React from "react";

import { Routes, Route, Outlet, Navigate } from "react-router-dom";

import { Filter, Modell, Modelle, ModellGruppe, Indicator } from "./containers";
import Grid from "@mui/material/Grid";

import { Detail } from "pages";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";
import Drawer from "@mui/material/Drawer";
import FilterIcon from "@mui/icons-material/FilterAlt";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
const Catalog = (): JSX.Element => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Modelle />} />
        </Route>
        <Route path="/" element={<Layout disableMake />}>
          <Route path="/mo/:modell_id" element={<Modell />} />
          <Route path="/mg/:modell_gruppe_id" element={<ModellGruppe />} />
        </Route>
        <Route path="/dt/:id" element={<Detail />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </>
  );
};

interface iLayout {
  disableMake?: boolean;
}

const Layout: React.FC<iLayout> = ({ disableMake = false }): JSX.Element => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  return (
    <>
      <Grid container>
        <Hidden mdDown>
          <Grid item xs={3}>
            <Filter disableMake={disableMake} />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Box
            bgcolor="primary.main"
            position="fixed"
            top={70}
            left={0}
            pt={1}
            zIndex={10}
            borderRadius="0 50% 50% 0"
            className="pointer"
            onClick={() => setDrawerOpen(true)}
          >
            <Box p={1} mr={1} color="white" fontSize="1rem">
              <FilterIcon />
            </Box>
          </Box>
          <Drawer
            style={{ width: 300 }}
            open={drawerOpen}
            className="pointer"
            onClose={() => setDrawerOpen(false)}
          >
            <Box
              width="100%"
              bgcolor="primary.main"
              p={2}
              color="white"
              fontSize="h6.fontSize"
              onClick={() => setDrawerOpen(false)}
            >
              <IconButton aria-label="delete" disabled color="primary">
                <ChevronLeftIcon fontSize="inherit" />
              </IconButton>
              &nbsp;FILTER
            </Box>
            <Filter disableMake={disableMake} />
          </Drawer>
        </Hidden>
        <Grid item xs={12} md={9}>
          <Indicator />
          <Outlet />
        </Grid>
      </Grid>
    </>
  );
};

export default Catalog;
