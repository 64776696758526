import React from "react";
import { useIntl } from "react-intl";

import Chip from "../../ChipIndicator";

interface IKilometerIndicator {
  filter: [number, number] | number | null;
  dispatch?: any;
}

const KilometerIndicator = ({
  filter,
  dispatch,
}: IKilometerIndicator): JSX.Element | null => {
  const { formatMessage: t } = useIntl();

  if (!filter) return null;

  const label =
    Array.isArray(filter) && filter[0] !== filter[1]
      ? t(
          {
            id: "common.kilometer.span",
            defaultMessage: "{from} bis {to} km",
          },
          { from: filter[0], to: filter[1] }
        )
      : t(
          {
            id: "common.kilometer.plural",
            defaultMessage:
              "{count, plural, =0 {keine km} one {eine Tür} other {# km}}",
          },
          { count: Array.isArray(filter) ? filter[0] : filter }
        );

  const handleDelete = (kilometer: any) => {
    dispatch({
      type: "UPDATE_FILTER",
      payload: { kilometer: null },
    });
  };

  return (
    <Chip
      id="kilometerChip"
      label={label}
      onDelete={() => handleDelete("kilometer")}
    />
  );
};

export default KilometerIndicator;
