import React from "react";
import { FormattedMessage } from "react-intl";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { TVehicle } from "types";

import { Tupel } from "components";

import { vehicle as defaultVehicle } from "fixtures";

interface IFeatures {
  vehicle: TVehicle;
  paperProps?: any;
}

const Features: React.FC<IFeatures> = ({
  vehicle = defaultVehicle,
  paperProps = {
    variant: "outlined",
    sx: { p: 2 },
  },
}): JSX.Element => {
  const {
    aussenfarbe_beschreibung,
    batteriekapazitaet,
    erstzulassung,
    farbe_aussen,
    gaenge,
    getriebe,
    hubraum,
    km,
    kw,
    metallic,
    mwst_ausweisbar,
    oldtimer,
    partikelfilter,
    sitze,
    taxi,
    tueren,
    unfallwagen,
    zylinder,
  } = vehicle;

  return (
    <Paper {...paperProps}>
      <Typography variant="h5">
        <FormattedMessage id="common.data" defaultMessage="Datn" />
      </Typography>
      {getriebe && getriebe.id && (
        <Tupel
          labelId="filter.getriebe.label"
          textId={`getriebe.${getriebe.id}.label`}
        />
      )}
      <Tupel labelId="properties.sitze.label" number={sitze} />
      <Tupel labelId="properties.tueren.label" number={tueren} />
      <Tupel labelId="properties.leistung.label" unit="kW" number={kw} />
      <Tupel labelId="properties.hubraum.label" number={hubraum} unit="cm³" />
      <Tupel labelId="properties.zylinder.label" number={zylinder} />
      <Tupel
        labelId="properties.laufleistung.label"
        number={km}
        unit="km"
        force
      />
      <Tupel
        labelId="common.aussenfarbe_beschreibung.label"
        text={aussenfarbe_beschreibung}
      />
      {farbe_aussen && farbe_aussen.id && (
        <Tupel
          labelId="filter.farbe_aussen.label"
          textId={`farbe.${farbe_aussen.id}.label`}
        />
      )}
      {metallic && (
        <Tupel labelId="properties.metallic.label" boolean={metallic} />
      )}
      {batteriekapazitaet && (
        <Tupel
          labelId="properties.batteriekapazitaet.label"
          number={batteriekapazitaet}
        />
      )}
      {erstzulassung && erstzulassung.length > 0 && (
        <Tupel labelId="properties.erstzulassung.label" date={erstzulassung} />
      )}
      <Tupel labelId="properties.gaenge.label" number={gaenge} />
      {/* <Tupel labelId="properties.hu_au_neu.label" boolean={hu_au_neu} /> */}
      {mwst_ausweisbar && (
        <Tupel
          labelId="properties.mwst_ausweisbar.label"
          boolean={mwst_ausweisbar}
        />
      )}
      {oldtimer && (
        <Tupel labelId="properties.oldtimer.label" boolean={oldtimer} />
      )}
      {partikelfilter && (
        <Tupel
          labelId="properties.partikelfilter.label"
          boolean={partikelfilter}
        />
      )}
      {taxi && <Tupel labelId="properties.taxi.label" boolean={taxi} />}
      {unfallwagen && (
        <Tupel labelId="properties.unfallwagen.label" boolean={unfallwagen} />
      )}
    </Paper>
  );
};

export default Features;
