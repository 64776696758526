import { schema } from "normalizr";

import { aufbauBereichSchema } from "./AufbauBereich";
import { aufbauSchema } from "./Aufbau";
import { ausstattungListSchema } from "./Ausstattung";
import { farbeSchema } from "./Farbe";
import { bankSchema } from "./Bank";
import { fzArtSchema } from "./FzArt";
import { getriebeSchema } from "./Getriebe";
import { hydraulikanlageSchema } from "./Hydraulikanlage";
import { kraftstoffSchema } from "./Kraftstoff";
import { locationSchema } from "./Location";
import { modellSchema } from "./Modell";
import { polsterSchema } from "./Polster";
import { qualitaetssiegelListSchema } from "./Qualitaetssiegel";
import { radformelSchema } from "./Radformel";
import { schadstoffSchema } from "./Schadstoff";
import { umweltplaketteSchema } from "./Umweltplakette";

const vehicle = {
  aufbau: aufbauSchema,
  aufbau_bereich: aufbauBereichSchema,
  ausstattung: ausstattungListSchema,
  farbe_aussen: farbeSchema,
  farbe_innen: farbeSchema,
  fzart: fzArtSchema,
  getriebe: getriebeSchema,
  hydraulikanlage: hydraulikanlageSchema,
  kraftstoff: kraftstoffSchema,
  location: locationSchema,
  modell: modellSchema,
  polster: polsterSchema,
  qualitaetssiegel: qualitaetssiegelListSchema,
  radformel: radformelSchema,
  schadstoff: schadstoffSchema,
  umweltplakette: umweltplaketteSchema,
};

const vehicleInListSchema = new schema.Entity("vehicle", vehicle);

const vehicleSchema = new schema.Entity(
  "vehicles",
  {
    ...vehicle,
    finance: [
      {
        mileageLease: { bank: bankSchema },
        credit: { bank: bankSchema },
        varioFinancing: { bank: bankSchema },
      },
    ],
  },
  { idAttribute: "wgnr" }
);

const vehicleListSchema = new schema.Array(vehicleInListSchema);

export { vehicleSchema, vehicleInListSchema, vehicleListSchema };
