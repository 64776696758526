import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";

import { TVehicle } from "types";
import { Tupel } from "components";

interface IEmissions {
  vehicle: TVehicle;
}

const Elektro: React.FC<IEmissions> = ({ vehicle }): JSX.Element => (
  <>
    <Tupel
      force
      labelId="term.antriebsart"
      label="Antriebsart"
      textId="term.elektromotor"
      text="Elektromotor"
    />
    <Tupel
      force
      labelId="term.kraftstoff"
      label="Kraftstoff"
      textId="term.entfaellt"
      text="entfällt"
    />
    <Tupel
      force
      labelId="term.anderer_energietraeger"
      label="Anderer Energietr&auml;ger"
      textId="term.strom"
      text="Strom"
    />
    <Typography variant="h6" sx={{ mt: 3 }}>
      <FormattedMessage
        id="term.Energieverbrauch"
        defaultMessage="Energieverbrauch"
      />
    </Typography>
    <Tupel
      force
      labelId="term.combined"
      label="kombiniert"
      float={vehicle.wltp_stromverbrauch_kombiniert}
      unit="kWh/100km"
      footnote={1}
    />
    <Typography variant="h6" sx={{ mt: 3 }}>
      <FormattedMessage
        id="term.co2Emission.default"
        defaultMessage="CO&#8322;-Emissionen"
      />
    </Typography>
    <Tupel
      force
      labelId="term.combined"
      label="kombinert"
      float={vehicle.wltp_co2_emissionen}
      unit="g/km"
      footnote={1}
    />
    <Typography variant="h6" sx={{ mt: 3 }}>
      <FormattedMessage
        id="term.elektrische_reichweite"
        defaultMessage="Elektrische Reichweite"
      />
    </Typography>
    <Tupel
      labelId="term.maximum"
      label="maximum"
      number={vehicle.wltp_elektro_reichweite_max || 0}
      unit="km"
    />
    <Tupel
      labelId="term.minimum"
      label="minimum"
      number={vehicle.wltp_elektro_reichweite_min || 0}
      unit="km"
    />
  </>
);

export default Elektro;
