import React from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { denormalize } from "normalizr";
import { FormattedMessage } from "react-intl";

import { useTheme } from "@mui/material/styles";
import { least } from "d3-array";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import BackToCatalog from "@mui/icons-material/Apps";
import BackToModell from "@mui/icons-material/TableRows";
import Next from "@mui/icons-material/NavigateNext";
import Before from "@mui/icons-material/NavigateBefore";

import { CartStore, CatalogStore, FilterStore } from "stores";
import { vehicleInListSchema, vehicleSchema } from "schemas";
import { TVehicle } from "types";
import { json2normalized } from "fixtures/helper";
import { HistoryLogger } from "containers";

import {
  Gallery,
  Backdrop,
  Features,
  Finance,
  Contact,
  Options,
  Energy,
  Dealer,
  Summary,
} from "./components";

import Header from "./Header";

export const DefaultFinanceFilter = {
  type: "buy",
};

const REACT_APP_MINIMUM_RATE = process.env.REACT_APP_MINIMUM_RATE
  ? parseInt(process.env.REACT_APP_MINIMUM_RATE)
  : 49;

const Detail: React.FC = (): JSX.Element | null => {
  const [financeFilter, setFinanceFilter] =
    React.useState<any>(DefaultFinanceFilter);

  const [financeRate, setFinanceRate] = React.useState<any>();
  const [detail, setDetail] = React.useState<TVehicle>();
  const [vehicle, setVehicle] = React.useState<TVehicle>();

  const {
    breakpoints: { values: breakpoints },
  } = useTheme();
  const {
    state: { entities },
  } = React.useContext(CatalogStore);

  const { state: cart, dispatch: updateCart } = React.useContext(CartStore);

  const {
    state: { filter: searchFilter },
  } = React.useContext(FilterStore);
  const { finance: searchFinanceFilter = null } = searchFilter;

  const navigate = useNavigate();

  const { id } = useParams();

  // Pull vehicle out of the store
  React.useEffect(() => {
    const vehicle: TVehicle = denormalize(id, vehicleInListSchema, entities);
    setVehicle(vehicle);
  }, [entities, id]);

  // Fetch detailed data
  React.useEffect(() => {
    const dataFetch = async () => {
      const data = await (
        await fetch(
          `https://ois4nuiftl.execute-api.eu-central-1.amazonaws.com/Prod/detail/${process.env.REACT_APP_SHOP_ID}/${id}`
        )
      ).json();
      const { vehicle, ...rest } = data;
      const { entities } = json2normalized(rest);
      const detail = denormalize(vehicle, vehicleSchema, entities);
      // set state when the data received
      setDetail(detail);
    };

    dataFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Align filter to search
  React.useEffect(() => {
    if (!vehicle) return;
    let newFilter: any = { type: "buy" };

    if (!vehicle.finance) {
      setFinanceFilter(newFilter);
      return;
    }

    if (cart.vehicles.length > 0) {
      const vehicleInCart = cart.vehicles[0];
      if (vehicleInCart.id === id) {
        newFilter = {
          deposit: vehicleInCart.finance.deposit || 0,
          mileage: vehicleInCart.finance.mileage || 0,
          runTime: vehicleInCart.finance.runtime || 0,
          type: vehicleInCart.finance.type,
        };
        setFinanceFilter(newFilter);
        return;
      }
    }

    if (searchFinanceFilter) {
      const {
        type,
        classicCarLoan,
        leasingMileage,
        balloonFinancing,
        mileage,
        runTime,
        deposit = 0,
        rate,
      }: any = searchFinanceFilter;

      if (type === "buy") {
        setFinanceRate({ type: "buy" });
        return;
      }

      const availableRates = getRates(vehicle.finance, { deposit }).filter(
        (e: any) => {
          if (!classicCarLoan && e.type === "credit") return false;
          if (!balloonFinancing && e.type === "balloon") return false;
          if (!leasingMileage && e.type === "leasing") return false;
          if (runTime < e.runtime) return false;
          if (mileage > e.mileage) return false;
          if (rate[0] >= e.rate || rate[1] <= e.rate) return false;
          return true;
        }
      );

      const bestRate = least(availableRates, (rate: any) => rate.rate);

      if (bestRate) {
        newFilter = {
          type: bestRate.type,
          deposit,
          mileage: bestRate.mileage || null,
          runTime: bestRate.runtime || null,
        };
      }
    } else {
      // Kein Filter vorgegeben -> Filter an bester Rate ausrichten
      const bestRate = least(
        getRates(vehicle.finance),
        (rate: any) => rate.rate
      );
      if (bestRate) {
        newFilter = {
          type: bestRate.type,
          deposit: 0,
          mileage: bestRate.mileage || null,
          runTime: bestRate.runtime || null,
        };
      }
    }

    setFinanceFilter(newFilter);
    return;
  }, [vehicle, searchFinanceFilter, cart, id]);

  // Calculate best Rate
  React.useEffect(() => {
    if (!(vehicle && financeFilter && detail && vehicle.wgnr === detail.wgnr)) {
      return;
    }

    if (financeFilter.type === "buy") {
      setFinanceRate({ type: "buy" });
      return;
    }

    const bestRate = least(
      getRates(vehicle.finance, financeFilter),
      (rate: any) => rate.rate
    );
    bestRate.maxDeposit =
      bestRate.originalRate * bestRate.runtime -
      bestRate.runtime * REACT_APP_MINIMUM_RATE;

    if (bestRate.maxDeposit < financeFilter.deposit) {
      setFinanceFilter({ ...financeFilter, deposit: bestRate.maxDeposit });
      return;
    }

    const rateDetail = detail.finance?.find(
      (e: any) => e.runtime === bestRate.runtime
    );

    if (!rateDetail) return;
    let subRate;

    switch (bestRate.type) {
      case "credit":
        setFinanceRate({
          ...rateDetail.credit,
          ...bestRate,
          deposit: financeFilter.deposit,
        });
        break;

      case "leasing":
        subRate = rateDetail.mileageLease.rate.find(
          (e: any) => e.mileage === bestRate.mileage
        );
        setFinanceRate({
          ...subRate,
          ...bestRate,
          deposit: financeFilter.deposit,
          bank: rateDetail.mileageLease.bank,
        });
        break;

      case "balloon":
        subRate = rateDetail.varioFinancing.rate.find(
          (e: any) => e.mileage === bestRate.mileage
        );
        setFinanceRate({
          ...subRate,
          ...bestRate,
          deposit: financeFilter.deposit,
          bank: rateDetail.varioFinancing.bank,
        });
        break;
    }
  }, [financeFilter, vehicle, detail]);

  // React.useEffect(() => console.log(financeRate), [financeRate]);

  // Move up the page
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [vehicle]);

  const addToCart = () => {
    updateCart({
      type: "REPLACE_VEHICLES",
      payload: { id: vehicle?.wgnr, finance: financeRate },
    });
    navigate("/checkout");
  };

  if (!vehicle) return null;

  const backdrop =
    vehicle && vehicle?.pictures && vehicle.pictures.length > 0
      ? vehicle.pictures[0].src
      : null;
  return (
    <>
      <HistoryLogger id={vehicle.wgnr} />
      <Header vehicle={vehicle} />
      {/* <Navigation modell_id={vehicle.modell.id} /> */}
      {backdrop && <Backdrop url={backdrop} />}
      <Box
        sx={{
          display: "grid",
          columnGap: 2,
          rowGap: 2,
          gridTemplateColumns: { lg: "repeat(6, 1fr)" },
        }}
      >
        <Gallery
          pictures={vehicle.pictures}
          paperProps={{
            variant: "outlined",
            sx: {
              p: 1,
              height: { xs: 200, sm: 350, lg: 450 },
              overflow: "hidden",
              gridColumn: { lg: "span 4" },
            },
          }}
          galleryProps={{
            spaceBetween: 2,
            breakpoints: {
              0: {
                slidesPerView: 2,
              },
              [breakpoints.sm]: {
                slidesPerView: 3,
              },
            },
          }}
        />
        <Summary
          {...{ vehicle, financeRate, financeFilter, action: addToCart }}
          paperProps={{
            variant: "outlined",

            sx: {
              p: 2,
              background: "#FFFFFFDD",
              opacity: 1,
              gridColumn: { lg: "span 2" },
            },
          }}
        />
        <Finance
          paperProps={{
            sx: { p: 2, gridColumn: { lg: "span 4" } },
          }}
          {...{
            vehicle,
            filter: financeFilter,
            setFilter: setFinanceFilter,
            financeRate,
          }}
        />
        <Features
          vehicle={vehicle}
          paperProps={{
            variant: "outlined",

            sx: {
              p: 2,
              background: "#FFFFFFDD",
              opacity: 1,
              gridColumn: { lg: "span 2", xl: "span 2" },
              gridRow: { lg: "span 2", xl: "span 1" },
            },
          }}
        />

        <Options
          paperProps={{
            sx: {
              p: 2,
              gridColumn: { lg: "span 6" },
            },
          }}
          vehicle={vehicle}
        />
        <Energy
          paperProps={{
            sx: {
              p: 2,
              gridColumn: { lg: "span 6" },
            },
          }}
          vehicle={vehicle}
        />
        <Dealer
          paperProps={{
            sx: { p: 2, gridColumn: { lg: "span 2" } },
          }}
        />
        <Contact
          paperProps={{
            sx: { p: 2, gridColumn: { lg: "span 4" } },
          }}
          vehicle={vehicle}
        />
      </Box>
    </>
  );
};

interface INavigation {
  modell_id: any;
  before_id?: any;
  next_id?: any;
}

const Navigation: React.FC<INavigation> = ({
  modell_id,
  before_id = null,
  next_id = null,
}): JSX.Element => (
  <Box
    sx={{
      display: "flex",
      position: "sticky",
      backgroundColor: "white",
      mb: 2,
      p: 1,
      justifyContent: "space-between",
      top: 0,
      zIndex: 10,
      width: "100%",
    }}
  >
    <div>
      <Button
        component={Link}
        to="/catalog"
        color="primary"
        startIcon={<BackToCatalog />}
      >
        <FormattedMessage id="navigation.catalog" />
      </Button>
      <Button
        component={Link}
        to={"/catalog/" + modell_id}
        color="primary"
        startIcon={<BackToModell />}
        sx={{ ml: 2 }}
      >
        <FormattedMessage id="navigation.modell" />
      </Button>
    </div>
    <div>
      {before_id && (
        <IconButton
          component={Link}
          to={`/catalog/${modell_id}/${before_id}`}
          aria-label="before"
        >
          <Before />
        </IconButton>
      )}
      {next_id && (
        <IconButton
          component={Link}
          to={`/catalog/${modell_id}/${next_id}`}
          aria-label="next"
        >
          <Next />
        </IconButton>
      )}
    </div>
  </Box>
);

export default Detail;

const getRates = (finance: any, filter: any = { deposit: 0 }) => {
  if (!finance) return false;
  const flatten = (rate: any): any[] => {
    const { runtime, credit = null, leasing = [], balloon = [] } = rate;

    const rates = [
      ...leasing.map(([mileage, rate]: [number, number]) => ({
        type: "leasing",
        runtime,
        mileage,
        rate,
      })),
      ...balloon.map(([mileage, rate]: [number, number]) => ({
        type: "balloon",
        runtime,
        mileage,
        rate,
      })),
    ];

    if (credit) {
      rates.push({ type: "credit", rate: credit, runtime });
    }

    return rates;
  };
  const rates = finance.map(flatten).flat();

  if (filter) {
    const REACT_APP_MINIMUM_RATE = process.env.REACT_APP_MINIMUM_RATE
      ? parseInt(process.env.REACT_APP_MINIMUM_RATE)
      : 0;
    const deposit = filter.deposit || 0;
    return rates
      .filter(({ type, runtime, mileage, rate }: any) => {
        if (filter.type && type !== filter.type) return false;
        if (filter.runTime && runtime > filter.runTime) return false;
        if (filter.mileage && mileage < filter.mileage) return false;
        return true;
      })

      .map(({ type, runtime, mileage, rate }: any) => ({
        type,
        runtime,
        mileage,
        originalRate: rate,
        rate:
          rate - deposit / runtime < REACT_APP_MINIMUM_RATE
            ? REACT_APP_MINIMUM_RATE
            : rate - deposit / runtime,
      }));
  }
  return rates;
};
