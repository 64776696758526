interface Iarray2normalized {
  input: any[];
  id?: string[];
}

export const array2normalized = ({
  input,
  id = ["id", "wgnr"],
}: Iarray2normalized): any => {
  const output: any = {};
  for (const element of input) {
    const idKey = id.find((e: string) => element.hasOwnProperty(e) && e);
    if (undefined === idKey) continue;
    const idValue = element[idKey];
    output[idValue] = element;
  }
  return output;
};

interface IJson {
  [key: string]: any;
}

interface IStore {
  entities: {
    [key: string]: { [key: string]: any };
  };
  result: {
    [key: string]: number[] | string[];
  };
}

export const json2normalized = (json: IJson): IStore => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const entities = <IJson>{};
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const result = <IJson>{};

  for (const [key, input] of Object.entries(json)) {
    entities[key] = array2normalized({ input });
    result[key] = input.map((e: any) => e.id || e.wgnr);
  }

  return { entities, result };
};
