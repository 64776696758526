import React, { FC, useState } from "react";
import { IntlProvider } from "react-intl";
import messages from "./messages";

const default_locale: string = process.env.REACT_APP_DEFAULT_LOCALE || "de";
const global_locales =
  process.env.REACT_APP_ALLOWED_LOCALES &&
  process.env.REACT_APP_ALLOWED_LOCALES.split(",");

export const allowed_locales: string[] = global_locales || ["de", "en"];

const getPreferredLocale = (): string => {
  return navigator.language;
};

const getLocale = (): string =>
  allowed_locales.find((locale: string) => getPreferredLocale() === locale) ||
  default_locale;

const initialLocale = getLocale();

export const IntlContext = React.createContext<{
  locale: string;
  setLocale: any;
}>({
  locale: initialLocale,
  setLocale: () => null,
});

const Intl: FC = ({ children }): JSX.Element => {
  const [locale, setLocale] = useState(initialLocale);
  return (
    <IntlContext.Provider value={{ locale, setLocale }}>
      <IntlProvider
        locale={locale}
        messages={messages[locale]}
        onError={(error: any) => {
          const {
            code,
            descriptor: { id, defaultMessage },
          } = error;
          if (process.env.NODE_ENV === "development") {
            if (code === "MISSING_TRANSLATION") {
              console.log(`,"${id}": "${defaultMessage}"`);
            }
          }
          return;
        }}
      >
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  );
};
export default Intl;
