import Cookies from "js-cookie";

const CookieReducer = (state: any, action: { type: any; payload: any }) => {
  const { type, payload = {} } = action;
  switch (type) {
    case "LOAD":
      const cookies = Cookies.get("carbando");
      if (!cookies) return {};
      const store = JSON.parse(cookies);
      return store;

    case "SET":
      const newState = { ...state, [payload.name]: payload.value };
      Cookies.set("carbando", JSON.stringify(newState));
      return newState;

    case "DELETE":
      delete state[payload.name];
      Cookies.set("carbando", JSON.stringify(state));
      return { ...state };

    default:
      return state;
  }
};

export default CookieReducer;
