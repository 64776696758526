import React from "react";

import { CatalogStore, FilterStore } from "stores";

interface IPipeline {
  type: string;
  defaultValue: any;
  Component: any;
  Reducer: any;
  Indicator: any;
}

const Pipeline: React.FC<IPipeline> = ({
  type,
  defaultValue,
  Component,
  Reducer,
  Indicator,
}) => {
  const {
    state: { entities },
  } = React.useContext(CatalogStore);

  const {
    state: {
      filter: { [type]: value = [] },
    },
    dispatch: dispatchFilter,
  } = React.useContext(FilterStore);

  React.useEffect(
    () => {
      dispatchFilter({
        type: "ADD_REDUCER",
        payload: { key: type, value: Reducer },
      });
      dispatchFilter({
        type: "ADD_INDICATOR",
        payload: { key: type, value: Indicator },
      });
      dispatchFilter({
        type: "INIT_FILTER",
        payload: { [type]: defaultValue },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!entities) return null;

  return <Component {...{ entities, value, dispatchFilter }} />;
};

export default Pipeline;
