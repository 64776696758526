import dayjs from "dayjs";

import { TVehicle } from "types";

interface IErstzulassungReducer {
  vehicles: TVehicle[];
  filter: any;
}

const ErstzulassungReducer = ({ vehicles, filter }: IErstzulassungReducer) => {
  if (!filter.erstzulassung) return vehicles;
  const left = filter.erstzulassung[0];
  const right = filter.erstzulassung[1];
  const currentYear = dayjs().year();

  return vehicles.filter((vehicle) => {
    const neuwagen = !vehicle.erstzulassung;
    if (right === currentYear && neuwagen) return true;
    const jahr = dayjs(vehicle.erstzulassung).year();
    return left <= jahr && right >= jahr;
  });
};

export default ErstzulassungReducer;
