import { denormalize } from "normalizr";
import { vehicleSchema } from "schemas";

import { NVehicle, TVehicle } from "types";

import { array2normalized } from "./helper";

import vehicleData from "./vehicle.json";

const entities: any = { vehicles: {} };

for (const [key, input] of Object.entries(vehicleData)) {
  if (key === "vehicle") continue;
  if (false === Array.isArray(input)) continue;
  const value = array2normalized({ input });
  entities[key] = value;
}

const vehicle: NVehicle = vehicleData.vehicle;

const wgnr = vehicle.wgnr;

entities.vehicles[wgnr] = vehicle;

const result: TVehicle = denormalize(wgnr, vehicleSchema, entities);

export default result;
