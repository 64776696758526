import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { extent } from "d3-array";
import dayjs from "dayjs";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Slider from "../Slider";

import { NVehicle } from "types";

import { CatalogStore, FilterStore } from "stores";

import Filter from "../../Filter";

import ErstzulassungReducer from "./ErstzulassungReducer";
import ErstzulassungIndicator from "./ErstzulassungIndicator";

const Pipeline: React.FC = (): JSX.Element | null => {
  const {
    state: {
      entities: { vehicle },
    },
  } = React.useContext(CatalogStore);

  const {
    state: {
      filter: { erstzulassung: value = null },
    },
    dispatch: dispatchFilter,
  } = React.useContext(FilterStore);

  React.useEffect(
    () => {
      dispatchFilter({
        type: "ADD_REDUCER",
        payload: { key: "erstzulassung", value: ErstzulassungReducer },
      });
      dispatchFilter({
        type: "ADD_INDICATOR",
        payload: { key: "erstzulassung", value: ErstzulassungIndicator },
      });
      dispatchFilter({
        type: "INIT_FILTER",
        payload: { erstzulassung: null },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!vehicle) return null;

  return <ErstzulassungFilter {...{ vehicle, value, dispatchFilter }} />;
};

interface IErstzulassungFilter {
  vehicle: {
    [id: string]: NVehicle;
  };
  value: [number, number];
  dispatchFilter: any;
}

export const ErstzulassungFilter: React.FC<IErstzulassungFilter> = ({
  vehicle,
  value,
  dispatchFilter,
}): JSX.Element | null => {
  const [maximum, setMaximum]: [number | undefined, any] = useState();
  const [minimum, setMinimum]: [number | undefined, any] = useState();
  const [range, setRange]: [[number, number] | undefined, any] = useState();

  React.useEffect(
    () => {
      if (!vehicle) return;
      const range: [any, any] = extent(
        Object.values(vehicle)
          .map((e: any) => e.erstzulassung)
          .filter((e: any) => !!e)
          .map((e: string) => dayjs(e, "YYYY-MM-DD").year())
      );
      setRange(range);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vehicle]
  );

  React.useEffect(
    () => {
      if (!(vehicle && range)) return;
      if (!value) {
        setMinimum(range[0]);
        setMaximum(range[1]);
      } else {
        setMinimum(value[0]);
        setMaximum(value[1]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, range]
  );

  const handleChange = (element: any, newValue: any) => {
    setMinimum(newValue[0]);
    setMaximum(newValue[1]);
  };

  const submit = (element: any, value: any) => {
    const newValue =
      JSON.stringify(range) === JSON.stringify(value) ? null : value;
    dispatchFilter({
      type: "UPDATE_FILTER",
      payload: { erstzulassung: newValue },
    });
  };

  if (minimum === undefined || maximum === undefined || !range) return null;

  const getTitle = () => {
    const id = "common.erstzulassung.span";
    let values = null;
    if (!value) {
      values = { from: range[0], to: range[1] };
    } else if (value[0] === value[1]) {
      return <span>{value[0]}</span>;
    } else {
      values = { from: value[0], to: value[1] };
    }

    return <FormattedMessage {...{ id, values }} />;
  };

  const marks = [
    { value: range[0], label: range[0] },
    { value: range[1], label: range[1] },
  ];

  if (minimum > range[0]) marks.push({ value: minimum, label: minimum });
  if (maximum < range[1] && minimum !== maximum)
    marks.push({ value: maximum, label: maximum });

  const options = {
    range,
    onChangeHandler: handleChange,
    onChangeCommittedHandler: submit,
    marks,
    step: 1,
  };

  const Component = (
    <Box sx={{ px: 1 }}>
      <Typography gutterBottom align="right" variant="body1" color="secondary">
        {getTitle()}
      </Typography>
      <Slider inverted value={[minimum, maximum]} {...options} />
    </Box>
  );

  return (
    <Filter
      component={Component}
      title={
        <FormattedMessage
          id="filter.erstzulassung.label"
          defaultMessage="Erstzulassung"
        />
      }
    />
  );
};

export default Pipeline;
