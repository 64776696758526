import { schema } from "normalizr";

import { markeSchema } from "./Marke";
import { modellGruppeSchema } from "./ModellGruppe";

const modellSchema = new schema.Entity(
  "modell",
  { marke: markeSchema, modell_gruppe: modellGruppeSchema },
  {},
  { idAttribute: "id" }
);

export { modellSchema };
