import { TVehicle } from "types";

interface ILocationReducer {
  vehicles: TVehicle[];
  filter: any;
}

const LocationReducer = ({ vehicles, filter }: ILocationReducer) => {
  if (!(filter.location && filter.location.length)) return vehicles;
  return vehicles.filter(
    ({ location }) => location && filter.location.includes(location.id)
  );
};

export default LocationReducer;
