import React from "react";
import { denormalize } from "normalizr";
import { Link, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Typography from "@mui/material/Typography";

import FullscreenIcon from "@mui/icons-material/Fullscreen";

import { CatalogStore } from "stores";
import { cookies } from "./HistoryLogger";

import { vehicleListSchema } from "schemas";
import { TVehicle } from "types";

interface IHistory {
  ids: (number | string)[];
  entities: any;
  sx?: any;
  link?: any;
}

export const History: React.FC<IHistory> = ({
  ids = [],
  entities,
  sx = {},
  link = () => null,
}): JSX.Element | null => {
  if (!ids.length) return null;
  const vehicles = denormalize(ids, vehicleListSchema, entities).filter(
    (e: any) => !!e
  );
  const height = 160;
  const width = 240;
  const gap = 10;
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Typography
        color="white"
        sx={{ position: "absolute", pl: 4 }}
        variant="h6"
      >
        Verlauf
      </Typography>
      <Box
        data-testid="container-history"
        sx={{
          ...sx,
          width: "100%",
          overflowY: "hidden",
          overflowX: "scroll",
        }}
      >
        <ImageList
          sx={{
            mt: 4,
            height,
            width: ids.length * width + (ids.length - 1) * gap,
          }}
          variant="standard"
          cols={ids.length}
          rowHeight={height}
          gap={gap}
        >
          {vehicles.map((vehicle: TVehicle) => (
            <Link to={link && link(vehicle.wgnr)} key={vehicle.wgnr}>
              <HistoryItem sx={{ height }} vehicle={vehicle} />
            </Link>
          ))}
        </ImageList>
      </Box>
      <Link to="/history">
        <FullscreenIcon
          sx={{
            position: "absolute",
            left: 1,
            top: 1,
            background: "grey",
            color: "white",
            "&:hover": {
              background: "darkgrey",
            },
          }}
        />
      </Link>
    </Box>
  );
};

interface IHistoryItem {
  vehicle: TVehicle;
  sx?: any;
}

const HistoryItem: React.FC<IHistoryItem> = ({
  vehicle,
  sx = {},
}): JSX.Element | null => {
  if (!(vehicle && vehicle.pictures.length)) return null;

  return (
    <ImageListItem sx={{ ...sx, "> img": { height: "inherit !important" } }}>
      <img
        src={vehicle.pictures[0].src}
        height={sx.height}
        alt={
          vehicle.modell.marke.name +
          " " +
          vehicle.modell.name +
          " " +
          vehicle.version
        }
        loading="lazy"
      />
      <ImageListItemBar
        title={vehicle.modell.marke.name + " " + vehicle.modell.name}
        subtitle={vehicle.version}
      />
    </ImageListItem>
  );
};

interface IPipeline {}

const Pipeline: React.FC<IPipeline> = (): JSX.Element | null => {
  const [ids, setIds] = React.useState<string[]>([]);
  const {
    state: { entities, result },
  } = React.useContext(CatalogStore);
  const location = useLocation();

  React.useEffect(() => {
    let ids: string[] = [];
    const historyIds = cookies.get("carbando.history");
    if (!historyIds) {
      cookies.set("carbando.history", ids);
    } else {
      const idsGone: string[] = historyIds.filter(
        (id: string) => result.vehicle && !result.vehicle.includes(id)
      );
      if (idsGone.length > 0) {
        ids = historyIds.filter((id: string) => !idsGone.includes(id));
        cookies.set("carbando.history", ids);
      }
    }
    setIds(ids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const historyIds = cookies.get("carbando.history");
    window.scrollTo(0, 0);
    setIds([...historyIds]);
  }, [location]);

  if (!(entities && result.vehicle && ids.length > 0)) return null;

  return (
    <History
      {...{ entities, ids }}
      link={(id: string) => `/catalog/dt/${id}`}
    />
  );
};

export default Pipeline;
