import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import TopicTechniques from "./components/TopicTechniques";
import TopicFeatures from "./components/TopicFeatures";
import TopicDealer from "./components/TopicDealer";

import { EnergyPass } from "../Modelle/ModelleCard";
import { TVehicle } from "types";

import { vehicle as defaultVehicle } from "fixtures";

const defaultPicture = "https://cdn.carbando.eu/develop/logo512.png";

interface IVehicleCard {
  vehicle: TVehicle;
  topic?: string;
  onClick?: any;
  variant?: string;
  paperProps?: any;
}
const VehicleCard: React.FC<IVehicleCard> = ({
  vehicle = defaultVehicle,
  topic = "features",
  onClick = () => null,
  paperProps = {},
}): JSX.Element => {
  const {
    pictures,
    modell,
    preis,
    ausstattung,
    fzart,
    rate,
    verbrauch_kombiniert,
    version,
    stromverbrauch_kombiniert,
    co2_emissionen,
    co2_effizienzklasse,
  } = vehicle;
  const topFeatures = ausstattung.slice(0, 5);
  const picture = pictures.length ? pictures[0].src : null;
  return (
    <Card variant="outlined" {...paperProps} onClick={() => onClick()}>
      <Grid container sx={{ height: "100%" }}>
        <Grid item xs={12} sm={3} sx={{ backgroundColor: "grey.300" }}>
          <CardMedia
            component="img"
            sx={{
              aspectRatio: "4/3",
              objectFit: picture ? "cover" : "scale-down",
            }}
            image={picture || defaultPicture}
          />
          <Box p={2}>
            <Typography variant="caption">
              <EnergyPass
                verbrauch_kombiniert={[
                  verbrauch_kombiniert,
                  verbrauch_kombiniert,
                ]}
                stromverbrauch_kombiniert={[
                  stromverbrauch_kombiniert,
                  stromverbrauch_kombiniert,
                ]}
                co2_emissionen={[co2_emissionen, co2_emissionen]}
                co2_effizienzklasse={[co2_effizienzklasse, co2_effizienzklasse]}
              />
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={9} sx={{ padding: 2 }}>
          <Typography
            gutterBottom
            variant="h4"
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {modell.marke.name}&nbsp;
            {modell.name}
          </Typography>
          <Typography
            gutterBottom
            variant="h5"
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {version}
          </Typography>
          <Typography gutterBottom variant="body2">
            <FormattedMessage
              id={`fzart.${fzart.id}.label`}
              defaultMessage={fzart.name}
            />
          </Typography>

          <Grid container>
            <Grid item xs={12} md={6}>
              <Box py={2} mr={2}>
                {topic === "features" && (
                  <TopicFeatures topFeatures={topFeatures} />
                )}
                {topic === "techniques" && (
                  <TopicTechniques vehicle={vehicle} />
                )}
                {topic === "location" && <TopicDealer />}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              {rate && (
                <Box mb={2} display="flex" alignItems="flex-end">
                  <Box flexGrow={1}>
                    <Typography variant="body1">
                      <FormattedMessage
                        id="common.monthly_rate_from"
                        values={{ short: true }}
                      />
                    </Typography>
                  </Box>
                  <Typography
                    color="primary"
                    variant="body1"
                    sx={{ fontSize: "2.25rem", fontWeight: 600 }}
                  >
                    <FormattedNumber
                      value={rate}
                      /* eslint-disable react/style-prop-object  */
                      style="currency"
                      currency="EUR"
                    />
                  </Typography>
                </Box>
              )}
              <Box mb={4} display="flex" typography="h5" alignItems="flex-end">
                <Box flexGrow={1}>
                  <Typography variant="body1">
                    <FormattedMessage id="common.preis_from" />
                  </Typography>
                </Box>
                <Typography color="inherit" variant="h6">
                  <FormattedNumber
                    value={preis}
                    // eslint-disable-next-line react/style-prop-object
                    style="currency"
                    currency="EUR"
                  />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default VehicleCard;
