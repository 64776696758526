import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import Markdown from "react-markdown";
import dayjs from "dayjs";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import LockIcon from "@mui/icons-material/Lock";
import MoneyIcon from "@mui/icons-material/Money";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ReplayIcon from "@mui/icons-material/Replay";

import { ConfigurationStore, History } from "containers";

const Footer = () => {
  const { formatMessage: t } = useIntl();

  const { footer = { social: undefined } }: any =
    useContext(ConfigurationStore);

  return (
    <Box
      sx={{
        color: "footer.color",
        bgcolor: "footer.backgroundColor",
        py: 3,
        mt: 5,
      }}
    >
      <Container maxWidth={"xl"}>
        <History />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ flexGrow: 1 }}>
            <ServiceLinks />
          </Box>
          <SocialLinks social={footer.social} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexWrap: "wrap",
            py: 2,
            gap: 2,
            mt: 2,
            borderTop: 1,
            borderColor: "footer.color",
          }}
        >
          <Typography
            id="footnote-1"
            variant="caption"
            sx={{
              a: { color: "white", textDecoration: "none", fontWeight: "bold" },
              "a:hover": { textDecoration: "underline" },
            }}
          >
            <Markdown>{`¹&nbsp;${t({ id: "memo.emission.md" })}`}</Markdown>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexWrap: "wrap",
            py: 2,
            gap: 2,
            mt: 2,
            borderTop: 1,
            borderBottom: 1,
            borderColor: "footer.color",
          }}
        >
          <FooterLink to="/withdrawal" intl="pages.withdrawal.headline" />
          <FooterLink to="/imprint" intl="pages.imprint.headline" />
          <FooterLink to="/privacy" intl="pages.privacy.headline" />
          <FooterLink to="/agb" intl="pages.agb.title" />
        </Box>
        <Typography component="p" variant="body2">
          Copyright {dayjs().year()} carbando GmbH
        </Typography>
      </Container>
    </Box>
  );
};

const ServiceLinks = (): JSX.Element => (
  <Box
    sx={{
      display: "flex",
      flexWrap: "wrap",
      gap: 2,
      color: "inherit",
    }}
  >
    <Typography
      variant="subtitle2"
      sx={{ display: "flex", alignContent: "center", gap: 1 }}
    >
      <LockIcon fontSize="small" />
      <FormattedMessage id="footer.service.securePay.label" />
    </Typography>
    <Typography
      variant="subtitle2"
      sx={{ display: "flex", alignContent: "center", gap: 1 }}
    >
      <MoneyIcon fontSize="small" />
      <FormattedMessage id="footer.service.cashPayment.label" />
    </Typography>
    <Typography
      variant="subtitle2"
      sx={{ display: "flex", alignContent: "center", gap: 1 }}
    >
      <AccessTimeIcon fontSize="small" />
      <FormattedMessage id="footer.service.financing.label" />
    </Typography>
    <Typography
      variant="subtitle2"
      sx={{ display: "flex", alignContent: "center", gap: 1 }}
    >
      <ReplayIcon fontSize="small" />
      <FormattedMessage id="footer.service.leasing.label" />
    </Typography>
  </Box>
);
const SocialLinks = ({
  social = {
    facebook: "https://www.facebook.com/kuhnundwitte/",
    instagram: "https://www.instagram.com/kuhnundwitte/",
    youtube: "https://www.youtube.com/channel/UC7bFBRClRuZvS7Zd0wSyOfQ",
    whatsapp: "https://web.whatsapp.com/send?phone=491755092428",
    twitter: "https://twitter.com",
  },
}: any): JSX.Element => (
  <Box
    sx={{
      display: "flex",
      flexWrap: "wrap",
      gap: 1,
      color: "inherit",
      typography: "h4",
    }}
  >
    {"youtube" in social && (
      <SocialLink icon={YouTubeIcon} href={social.youtube} />
    )}
    {"twitter" in social && (
      <SocialLink icon={TwitterIcon} href={social.twitter} />
    )}
    {"facebook" in social && (
      <SocialLink icon={FacebookIcon} href={social.facebook} />
    )}
    {"instagram" in social && (
      <SocialLink icon={InstagramIcon} href={social.instagram} />
    )}
    {"whatsapp" in social && (
      <SocialLink icon={WhatsAppIcon} href={social.whatsapp} />
    )}
  </Box>
);

interface ISocialLink {
  href: string;
  icon: any;
}
const SocialLink = ({ icon: Icon, href }: ISocialLink): JSX.Element => (
  <IconButton
    color="inherit"
    size="large"
    rel="noopener"
    target="_blank"
    href={href}
  >
    <Icon fontSize="inherit" />
  </IconButton>
);

interface IFooterLink {
  to: string;
  intl: string;
}

const FooterLink = ({ to, intl }: IFooterLink): JSX.Element => (
  <Link
    sx={{ "&:hover": { color: "primary.main" } }}
    color="inherit"
    variant="subtitle2"
    component={RouterLink}
    to={to}
  >
    <FormattedMessage id={intl} defaultMessage={to} />
  </Link>
);

export default Footer;
