/* eslint-disable react/jsx-pascal-case */
import React from "react";

import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";

import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";

import Recall from "./Recall";
import Message from "./Message";
import WhatsApp from "./WhatsApp";

interface IContactSpeedDial {
  sx?: any;
  variants?: string[];
}

const ContactSpeedDial: React.FC<IContactSpeedDial> = ({
  sx = {},
  variants = ["recall", "message", "whatsapp"],
}): JSX.Element => {
  const [overlays, setOverlays] = React.useState({
    recall: false,
    message: false,
    whatsapp: false,
  });

  const openOverlay = (which: string) => {
    const newOverlays = { ...overlays, [which]: true };
    setOverlays(newOverlays);
  };

  const closeOverlay = (which: string) => {
    const newOverlays = { ...overlays, [which]: false };
    setOverlays(newOverlays);
  };

  return (
    <>
      <SpeedDial
        sx={sx}
        ariaLabel="Kontakt"
        icon={<ConnectWithoutContactIcon />}
      >
        {variants && variants.includes("recall") && (
          <SpeedDialAction
            icon={Recall.speedDialIcon}
            tooltipTitle="Rückruf"
            onClick={() => openOverlay("recall")}
          />
        )}
        {variants && variants.includes("message") && (
          <SpeedDialAction
            icon={Message.speedDialIcon}
            tooltipTitle="Nachricht"
            onClick={() => openOverlay("message")}
          />
        )}
        {variants && variants.includes("whatsapp") && (
          <SpeedDialAction
            icon={WhatsApp.speedDialIcon}
            tooltipTitle="WhatsApp"
            onClick={() => openOverlay("whatsapp")}
          />
        )}
      </SpeedDial>
      {variants && variants.includes("recall") && (
        <Recall.overlay
          open={overlays.recall}
          closeOverlay={() => closeOverlay("recall")}
        />
      )}
      {variants && variants.includes("message") && (
        <Message.overlay
          open={overlays.message}
          closeOverlay={() => closeOverlay("message")}
        />
      )}
      {variants && variants.includes("whatsapp") && (
        <WhatsApp.overlay
          open={overlays.whatsapp}
          closeOverlay={() => closeOverlay("whatsapp")}
        />
      )}
    </>
  );
};

export default ContactSpeedDial;
