import React from "react";
import { FormattedMessage } from "react-intl";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { EnergyLabel, Tupel } from "components";

import { TVehicle } from "types";

import {
  Autogas,
  Benzin,
  DefaultVehicle,
  Diesel,
  Elektro,
  MildHybridBenzin,
  MildHybridDiesel,
  PlugInHybridBenzin,
  PlugInHybridDiesel,
  Wasserstoff,
} from "./variants";

import {
  Verbrenner as Verbrennerinformation,
  Elektro as ElektroInformation,
  PlugInHybrid as PlugInHybridInformation,
} from "./information";

interface IEnergy {
  paperProps?: any;
  vehicle: TVehicle;
}

const Energy: React.FC<IEnergy> = ({
  paperProps = { sx: { p: 2 } },
  vehicle,
}): JSX.Element | null => {
  if (!(vehicle && vehicle.kraftstoff)) return null;

  let Variant;
  let AdditionalInformation = Verbrennerinformation;
  switch (vehicle.kraftstoff.name) {
    case "Autogas/LPG": {
      Variant = Autogas;
      break;
    }
    case "Benzin": {
      Variant = Benzin;
      break;
    }
    case "Super E5": {
      Variant = Benzin;
      break;
    }
    case "Super E10 95": {
      Variant = Benzin;
      break;
    }
    case "Diesel": {
      Variant = Diesel;
      break;
    }
    case "Elektro": {
      AdditionalInformation = ElektroInformation;
      Variant = Elektro;
      break;
    }
    case "Hybrid-Benzin": {
      AdditionalInformation = vehicle.plugin_hybrid
        ? PlugInHybridInformation
        : Verbrennerinformation;
      Variant = vehicle.plugin_hybrid ? PlugInHybridBenzin : MildHybridBenzin;
      break;
    }
    case "Hybrid-Diesel": {
      AdditionalInformation = vehicle.plugin_hybrid
        ? PlugInHybridInformation
        : Verbrennerinformation;
      Variant = vehicle.plugin_hybrid ? PlugInHybridDiesel : MildHybridDiesel;
      break;
    }
    case "Wasserstoff": {
      Variant = Wasserstoff;
      break;
    }
    default: {
      return null;
    }
  }
  return (
    <Paper variant="outlined" {...paperProps}>
      <Typography variant="h5" sx={{ mb: 3 }}>
        <FormattedMessage
          id="common.verbrauchsangaben"
          defaultMessage="Information über den Energieverbrauch und die CO&#8322;-Emissionen"
        />
      </Typography>
      <Grid container spacing={4} sx={{ typography: "body1" }}>
        <Grid item xs={12} md={4}>
          <Tupel
            force
            labelId="term.marke"
            label="Marke"
            text={vehicle.modell.marke.name}
          />
          <Tupel
            force
            labelId="term.handelsbezeichnung"
            label="Handelsbezeichnung"
            text={vehicle.modell.name}
          />
          <Tupel
            force
            labelId="term.umweltplakette"
            label="Umweltplakette"
            text={vehicle.umweltplakette?.name || ""}
          />
          <Variant vehicle={vehicle} />
        </Grid>
        <Grid item xs={12} md={4}>
          <AdditionalInformation vehicle={vehicle} />
        </Grid>
        <Grid item xs={12} md={4}>
          <CO2Class vehicle={vehicle} />
        </Grid>
      </Grid>
    </Paper>
  );
};

interface ICO2Class {
  vehicle: TVehicle;
}

const CO2Class: React.FC<ICO2Class> = ({ vehicle }): JSX.Element | null => {
  if (vehicle.co2_emissionen === null) return null;

  return (
    <>
      <Tupel
        force
        labelId="term.co2-klasse"
        label="CO&#8322;-Klasse"
        text={getEmissionClass(vehicle.wltp_co2_emissionen)}
      />
      {vehicle.plugin_hybrid && (
        <Tupel
          force
          labelId="term.co2-klasse"
          label="CO&#8322;-Klasse bei entladener Batterie"
          text={getEmissionClass(vehicle.wltp_co2_emissionen_entladen)}
        />
      )}
      <EnergyLabel
        svgWidth={380}
        base="A"
        baseWidth={60}
        barHeight={24}
        indicator={getEmissionClass(vehicle.wltp_co2_emissionen)}
        indicatorB={
          vehicle.plugin_hybrid
            ? getEmissionClass(vehicle.wltp_co2_emissionen_entladen)
            : undefined
        }
      />
      <Typography paragraph variant="caption">
        <FormattedMessage
          id="emission.co2.disclaimer"
          defaultMessage="CO&#8322;-Klasse auf der Grundlage der gemessenen CO&#8322;-Emissionen des
      Fahrzeugs ermittelt."
        />
      </Typography>
    </>
  );
};

export default Energy;

const getEmissionClass = (value: number): string => {
  const emissionClass = emissionsClasses.find(
    ({ maximum }) => value <= maximum
  );
  return emissionClass ? emissionClass.name : "G";
};

const emissionsClasses = [
  { name: "A", minimum: 0, maximum: 0 },
  { name: "B", minimum: 1, maximum: 95 },
  { name: "C", minimum: 96, maximum: 115 },
  { name: "D", minimum: 116, maximum: 135 },
  { name: "E", minimum: 136, maximum: 155 },
  { name: "F", minimum: 156, maximum: 175 },
];
