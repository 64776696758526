import React from "react";

import Stack from "@mui/material/Stack";
import InfoIcon from "@mui/icons-material/InfoOutlined";

interface IEmptySearch {}

const EmptySearch: React.FC<IEmptySearch> = (): JSX.Element => {
  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        typography: "h4",
        color: "text.secondary",
        mt: 15,
        width: "100%",
      }}
      spacing={3}
    >
      <InfoIcon color="inherit" sx={{ fontSize: "1.5em" }} />
      <span>
        Ihre Suche ergab leider kein Ergebnis.
        <br />
        Bitte ändern Sie die Filter.
      </span>
    </Stack>
  );
};

export default EmptySearch;
