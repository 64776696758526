import React from "react";
import { scaleLinear } from "d3-scale";

import { default as MuiAutocomplete } from "@mui/material/Autocomplete";
import { default as MuiTextField } from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

interface IComponent {
  onChangeHandler: any;
  onChangeCommittedHandler: any;
}

interface IautoComplete extends IComponent {
  range: [number, number];
  value: [number, number];
}

const AutoComplete: React.FC<IautoComplete> = ({
  range,
  value,
  onChangeHandler,
  onChangeCommittedHandler,
}): JSX.Element => {
  const [max, setMax] = React.useState("");
  const [min, setMin] = React.useState("");

  const minimum = value[0];
  const maximum = value[1];

  React.useCallback(() => {
    setMin(value[0].toString());
    setMax(value[1].toString());
  }, [value]);

  const scale = scaleLinear(range, ["red", "blue"]);
  const marks = scale.ticks().map((x: number) => x.toString());

  const setMinimum = (e: any) => {
    if (!max) return;
    const newMin = Number.parseInt(e);
    const newMax = Number.parseInt(max);
    const result = newMin > newMax ? [newMax, newMin] : [newMin, newMax];
    if (result[0] < range[0]) result[0] = range[0];
    onChangeCommittedHandler(null, result);
  };

  const setMaximum = (e: any) => {
    if (!max) return;
    const newMin = Number.parseInt(min);
    const newMax = Number.parseInt(e);
    const result = newMin > newMax ? [newMax, newMin] : [newMin, newMax];
    if (result[1] > range[1]) result[1] = range[1];
    onChangeCommittedHandler(null, result);
  };

  return (
    <Grid container spacing="0" justifyContent="flex-end">
      <Grid item xs>
        <MuiAutocomplete
          value={minimum.toString()}
          size="small"
          onChange={(event: any, newValue: string | null) => {
            setMinimum(newValue);
          }}
          inputValue={min}
          onInputChange={(event, newInputValue) => {
            setMinimum(newInputValue);
            setMin(newInputValue);
          }}
          id="controllable-states-demo"
          disableClearable
          freeSolo={true}
          options={marks}
          getOptionDisabled={(option) => Number.parseInt(option) >= maximum}
          // getOptionlable={(option: any) => option.toString()}
          sx={{ width: "90%" }}
          renderInput={(params) => {
            return <MuiTextField type="number" {...params} label="von" />;
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <MuiAutocomplete
          value={maximum.toString()}
          size="small"
          onChange={(event: any, newValue: string | null) => {
            setMaximum(newValue);
          }}
          inputValue={max}
          onInputChange={(event, newInputValue) => {
            setMax(newInputValue);
          }}
          getOptionDisabled={(option) => Number.parseInt(option) <= minimum}
          id="controllable-states-demo"
          disableClearable
          freeSolo={true}
          options={marks}
          // getOptionlable={(option: any) => option.toString()}
          sx={{ width: "90%" }}
          renderInput={(params) => (
            <MuiTextField type="number" {...params} label="bis" />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default AutoComplete;
