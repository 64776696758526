import React from "react";
import { useIntl } from "react-intl";

import Chip from "../../ChipIndicator";

interface IFzartIndicator {
  filter: number[] | string[];
  dispatch?: any;
}

const FzartIndicator = ({
  filter = [],
  dispatch,
}: IFzartIndicator): JSX.Element | null => {
  const { formatMessage: t } = useIntl();
  if (!filter.length) return null;

  const handleDelete = (fzart: any) => {
    dispatch({
      type: "REMOVE_ID_FROM_FILTER",
      payload: { filter: "fzart", id: fzart },
    });
  };
  return (
    <>
      {filter.map((fzart: string | number) => (
        <Chip
          id={fzart}
          key={fzart}
          label={t({
            id: `fzart.${fzart}.label`,
          })}
          onDelete={() => handleDelete(fzart)}
        />
      ))}
    </>
  );
};

export default FzartIndicator;
