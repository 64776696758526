/*
 * http://www.gesetze-im-internet.de/pkw-envkv/__3a.html
 */

const levels = new Map();
levels.set(1, "#00A54E");
levels.set(2, "#4BB848");
levels.set(3, "#BED630");
levels.set(4, "#FFF200");
levels.set(5, "#FCB813");
levels.set(6, "#F36F21");
levels.set(7, "#EF1C22");

const colors = {
  "A+": {
    "A+": 1, // A + 100% Cyan, 0% Magenta, 100% Gelb
    A: 1, // A   100% Cyan, 0% Magenta, 100% Gelb
    B: 2, // B   70% Cyan, 0% Magenta, 100% Gelb
    C: 3, // C   30% Cyan, 0% Magenta, 100% Gelb
    D: 4, // D   0% Cyan, 0% Magenta, 100% Gelb
    E: 5, // E   0% Cyan, 30% Magenta, 100% Gelb
    F: 6, // F   0% Cyan, 70% Magenta, 100% Gelb
    G: 7, // G   0% Cyan, 100% Magenta, 100% Gelb
  },
  "A++": {
    "A++": 1, //A++ 100% Cyan, 0% Magenta, 100% Gelb
    "A+": 1, //A+   100% Cyan, 0% Magenta, 100% Gelb
    A: 2, //A   70% Cyan, 0% Magenta, 100% Gelb
    B: 3, //B 30% Cyan, 0% Magenta, 100% Gelb
    C: 3, //C   30% Cyan, 0% Magenta, 100% Gelb
    D: 4, //D   0% Cyan, 0% Magenta, 100% Gelb
    E: 6, //E   0% Cyan, 70% Magenta, 100% Gelb
    F: 7, //F  0% Cyan, 100% Magenta, 100% Gelb.
    G: 7, //G   0% Cyan, 100% Magenta, 100% Gelb.
  },
  "A+++": {
    "A+++": 1, // A +++, 100% Cyan, 0% Magenta, 100% Gelb
    "A++": 1, // A ++   100% Cyan, 0% Magenta, 100% Gelb
    "A+": 2, // A +   70% Cyan, 0% Magenta, 100% Gelb
    A: 3, // A 30% Cyan, 0% Magenta, 100% Gelb
    B: 3, // B   30% Cyan, 0% Magenta, 100% Gelb
    C: 4, // C   0% Cyan, 0% Magenta, 100% Gelb
    D: 6, // D   0% Cyan, 70% Magenta, 100% Gelb
    E: 7, // E 0% Cyan, 100% Magenta, 100% Gelb.
    F: 7, // F 0% Cyan, 100% Magenta, 100% Gelb.
    G: 7, // G   0% Cyan, 100% Magenta, 100% Gelb.
  },
  A: {
    A: 1, // A   100% Cyan, 0% Magenta, 100% Gelb
    B: 2, // B   70% Cyan, 0% Magenta, 100% Gelb
    C: 3, // C   30% Cyan, 0% Magenta, 100% Gelb
    D: 4, // D   0% Cyan, 0% Magenta, 100% Gelb
    E: 5, // E   0% Cyan, 30% Magenta, 100% Gelb
    F: 6, // F   0% Cyan, 70% Magenta, 100% Gelb
    G: 7, // G   0% Cyan, 100% Magenta, 100% Gelb
  },
};

export const getColorCode = (efficiencyClass, base = "A+") => {
  const level = levels.get(colors[base][efficiencyClass]);
  return level;
};
