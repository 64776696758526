import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";

import { TVehicle } from "types";
import { Tupel } from "components";

interface IEmissions {
  vehicle: TVehicle;
}

const MildHybridDiesel: React.FC<IEmissions> = ({ vehicle }): JSX.Element => {
  return (
    <>
      <Tupel
        force
        labelId="term.antriebsart"
        label="Antriebsart"
        textId="term.plugin_hybrid"
        text="Plug-In-Hybrid"
      />
      <Tupel
        force
        labelId="term.kraftstoff"
        label="Kraftstoff"
        textId="term.diesel"
        text="Diesel"
      />
      <Tupel
        force
        labelId="term.anderer_energietraeger"
        label="Anderer Energietr&auml;ger"
        textId="term.entfaellt"
        text="entfällt"
      />
      <Typography variant="h6" sx={{ mt: 3 }}>
        <FormattedMessage
          id="term.Energieverbrauch"
          defaultMessage="Energieverbrauch"
        />
      </Typography>
      <Tupel
        force
        labelId="term.combined"
        label="kombiniert"
        float={vehicle.wltp_kombiniert}
        unit="l/100km"
        footnote={1}
      />
      <Typography variant="h6" sx={{ mt: 3 }}>
        <FormattedMessage
          id="term.co2Emission.default"
          defaultMessage="CO&#8322;-Emissionen"
        />
      </Typography>
      <Tupel
        force
        labelId="term.combined"
        label="kombinert"
        number={vehicle.wltp_co2_emissionen}
        unit="g/km"
        footnote={1}
      />
    </>
  );
};

export default MildHybridDiesel;
