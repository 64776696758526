import React from "react";
import { useNavigate } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

import { FilterStore } from "stores";

interface IResetFilter {
  sx?: any;
}

const ResetFilter: React.FC<IResetFilter> = ({ sx }): JSX.Element => {
  const navigate = useNavigate();
  const { dispatch } = React.useContext(FilterStore);
  const resetFilter = () => {
    const payload = {
      type: "RESET_FILTER",
    };
    dispatch(payload);
    navigate("/catalog");
  };
  return (
    <>
      <IconButton size="small" sx={sx} onClick={() => resetFilter()}>
        <FilterAltOffIcon />
      </IconButton>
    </>
  );
};

export default ResetFilter;
