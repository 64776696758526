import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as d3 from "d3-array";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";

import { VehicleStore, CatalogStore, FilterStore } from "stores";

import List, { CollapsedListFilter } from "../../List";
import Filter from "../../Filter";

import AusstattungReducer from "./AusstattungReducer";
import AusstattungIndicator from "./AusstattungIndicator";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";

interface ISearchField {
  value: string;
  onChange: any;
  label?: any;
}

export const SearchField: React.FC<ISearchField> = ({
  value,
  onChange,
  label = "Suche",
}): JSX.Element => (
  <TextField
    fullWidth
    value={value}
    onChange={onChange}
    label={label}
    type="search"
    variant="outlined"
    size="small"
  />
);

const AusstattungFilter: React.FC = (): JSX.Element | null => {
  const { state: vehicles } = React.useContext(VehicleStore);
  const [search, setSearch] = React.useState("");
  const { formatMessage: t } = useIntl();

  const {
    state: { filter },
    dispatch,
  } = React.useContext(FilterStore);

  const {
    state: {
      entities: {
        ausstattung: ausstattungEntities,
        ausstattungsgruppe: ausstattungsgruppeEntities,
      },
      result: { ausstattung: ausstattungIds },
    },
  } = React.useContext(CatalogStore);

  const changeSearch = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearch(event.target.value);
  };

  React.useEffect(
    () => {
      dispatch({
        type: "ADD_REDUCER",
        payload: { key: "ausstattung", value: AusstattungReducer },
      });
      dispatch({
        type: "ADD_INDICATOR",
        payload: { key: "ausstattung", value: AusstattungIndicator },
      });
      dispatch({
        type: "INIT_FILTER",
        payload: { ausstattung: [] },
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  if (
    !(
      vehicles &&
      ausstattungIds &&
      ausstattungEntities &&
      ausstattungsgruppeEntities
    )
  )
    return null;

  const setValueHandler = (value: any) => {
    if (value.length === ausstattungIds.length)
      dispatch({ type: "UPDATE_FILTER", payload: { ausstattung: [] } });
    else dispatch({ type: "UPDATE_FILTER", payload: { ausstattung: value } });
  };

  const selectAll = () => {
    dispatch({ type: "UPDATE_FILTER", payload: { ausstattung: [] } });
  };

  const options = ausstattungIds.map((id: number) => ({
    value: id,
    title: t({
      id: `ausstattung.${id}.label`,
      defaultMessage: ausstattungEntities[id].name,
    }),
    group: ausstattungEntities[id].ausstattungsgruppe,
  }));

  const groupedOptions = d3.group(options, (d: any) => d.group);

  const searchedOptions = options.filter(
    ({ title }: { title: string }) =>
      search.length > 1 &&
      title.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
  );

  const Component = (
    <Box width={1}>
      <SearchField
        value={search}
        onChange={changeSearch}
        label={t({ id: "common.search", defaultMessage: "Suche" })}
      />
      <List
        value={filter.ausstattung || []}
        options={searchedOptions}
        setValueHandler={setValueHandler}
      />
      <Divider />
      <ListItem
        id="filter-marke-model-filter-all"
        key="all"
        role={undefined}
        dense
        button
        onClick={() => selectAll()}
      >
        <ListItemIcon style={{ minWidth: 40 }}>
          <Checkbox
            color="primary"
            edge="start"
            checked={!filter.ausstattung || !filter.ausstattung.length}
            tabIndex={-1}
            inputProps={{ "aria-labelledby": "checkbox-list-label-all" }}
          />
        </ListItemIcon>
        <ListItemText
          className="list-item-text"
          primary="Alle"
          primaryTypographyProps={{
            variant: "body1",
            component: "span",
          }}
        />
      </ListItem>
      <Divider />
      {Array.from(groupedOptions, ([key, values]) => {
        return (
          <CollapsedListFilter
            label={t({
              id: `ausstattungsgruppe.${key}.label`,
              defaultMessage: ausstattungsgruppeEntities[key].name,
            })}
            key={key}
          >
            <List
              value={filter.ausstattung || []}
              options={values}
              setValueHandler={setValueHandler}
            />
          </CollapsedListFilter>
        );
      })}
    </Box>
  );

  return (
    <Filter
      component={Component}
      title={
        <FormattedMessage
          id="common.ausstattung"
          defaultMessage="Ausstattungen"
        />
      }
    />
  );
};

export default AusstattungFilter;
