import { TVehicle } from "types";

interface IModellReducer {
  vehicles: TVehicle[];
  filter: any;
}

const ModellReducer = ({ vehicles, filter }: IModellReducer) => {
  if (
    !(
      (filter.modell && filter.modell.length) ||
      (filter.modell_gruppe && filter.modell_gruppe.length)
    )
  )
    return vehicles;
  return vehicles.filter(
    ({
      modell: {
        id: modellId,
        modell_gruppe: modellGruppe,
        marke: { id: markeId },
      },
    }) => {
      if (filter.marke && filter.marke.includes(markeId)) return true;
      if (
        filter.modell_gruppe &&
        modellGruppe &&
        filter.modell_gruppe.includes(modellGruppe.id)
      )
        return true;
      return filter.modell.includes(modellId);
    }
  );
};

export default ModellReducer;
