import React from "react";
import { motion } from "framer-motion";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";

import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import XIcon from "@mui/icons-material/X";
import MailIcon from "@mui/icons-material/Mail";
import ShareIcon from "@mui/icons-material/Share";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import RedditIcon from "@mui/icons-material/Reddit";
import TelegramIcon from "@mui/icons-material/Telegram";

import { encrypt } from "./helper";
import type { TVehicle } from "types";

interface IShare {
  vehicle: TVehicle;
  open?: boolean;
  closeHandler: any;
}

export const Share: React.FC<IShare> = ({
  vehicle,
  open = false,
  closeHandler = () => null,
}): JSX.Element | null => {
  const shopId = process.env.REACT_APP_SHOP_ID;
  const vehicleId = vehicle.wgnr;
  const encrypted = encrypt(`${shopId},${vehicleId}`);

  if (typeof encrypted !== "string") return null;
  const affiliateLink = "https://shop.carbando.eu/affiliate/" + encrypted;

  return (
    <Dialog open={open}>
      <DialogTitle sx={{ minWidth: "400px" }}>Fahrzeug empfehlen</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => closeHandler()}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Box sx={{ textAlign: "center" }}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            sx={{
              fontSize: "4rem",
            }}
          >
            <Mail url={affiliateLink} vehicle={vehicle} />
            <WhatsApp url={affiliateLink} />
            <Instagram url={affiliateLink} />
            <Facebook url={affiliateLink} />
            <Reddit url={affiliateLink} />
            <Telegram url={affiliateLink} />
            <X url={affiliateLink} />
          </Stack>
        </Box>
        <Divider sx={{ my: 2 }} />
        <CopyToClipboard url={affiliateLink} />
      </DialogContent>
    </Dialog>
  );
};

interface ICopyToClipboard {
  url: string;
}

const CopyToClipboard: React.FC<ICopyToClipboard> = ({ url }): JSX.Element => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(url).then(
      () => {
        console.log("Link kopiert: " + url);
        /* Resolved - text copied to clipboard successfully */
      },
      () => {
        console.error("Failed to copy");
        /* Rejected - text failed to copy to the clipboard */
      }
    );
  };

  return (
    <Paper variant="outlined">
      <Stack direction="row">
        <Typography
          sx={{
            p: 1,
            width: 1,
            fontFamily: "monospace",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {url}
        </Typography>
        <IconButton onClick={() => copyToClipboard()}>
          <ContentCopyIcon />
        </IconButton>
      </Stack>
    </Paper>
  );
};

interface IMail {
  vehicle: TVehicle;
  url: string;
}

const Mail: React.FC<IMail> = ({ url, vehicle }): JSX.Element => {
  const { modell, version } = vehicle;
  const description = `${modell.marke.name} ${modell.name} ${version}`;
  const subject = encodeURIComponent(`Empfehlung: ${description}`);
  const body = encodeURIComponent(
    `Schau Dir an, was ich gefunden habe:\n\n${description}\n\n${url}`
  );

  const MailLink = `mailto:?subject=${subject}&body=${body}`;
  return (
    <IconButton
      sx={{
        backgroundColor: "darkGrey",
        "&:hover": { backgroundColor: "grey" },
      }}
      size="large"
      aria-label="WhatsApp"
      href={MailLink}
      target="_blank"
    >
      <MailIcon sx={{ color: "white", fontSize: "inherit" }} />
    </IconButton>
  );
};

interface IWhatsApp {
  url: string;
}
const WhatsApp: React.FC<IWhatsApp> = ({ url }): JSX.Element => {
  const WhatsAppLink =
    "https://api.whatsapp.com/send/?type=custom_url&app_absent=0&text=";
  const AffiliateLink = WhatsAppLink + encodeURIComponent(url);
  return (
    <IconButton
      sx={{
        backgroundColor: "darkGrey",
        "&:hover": { backgroundColor: "grey" },
      }}
      size="large"
      aria-label="WhatsApp"
      href={AffiliateLink}
      target="_blank"
    >
      <WhatsAppIcon sx={{ color: "white", fontSize: "inherit" }} />
    </IconButton>
  );
};

interface IWhatsApp {
  url: string;
}
const Facebook: React.FC<IWhatsApp> = ({ url }): JSX.Element => {
  const Link = "https://www.addtoany.com/add_to/facebook?linkurl=";
  const AffiliateLink = Link + encodeURIComponent(url);
  return (
    <IconButton
      sx={{
        backgroundColor: "darkGrey",
        "&:hover": { backgroundColor: "grey" },
      }}
      size="large"
      aria-label="WhatsApp"
      href={AffiliateLink}
      target="_blank"
    >
      <FacebookIcon sx={{ color: "white", fontSize: "inherit" }} />
    </IconButton>
  );
};

const Reddit: React.FC<IWhatsApp> = ({ url }): JSX.Element => {
  const Link = "https://www.addtoany.com/add_to/reddit?linkurl=";
  const AffiliateLink = Link + encodeURIComponent(url);
  return (
    <IconButton
      sx={{
        backgroundColor: "darkGrey",
        "&:hover": { backgroundColor: "grey" },
      }}
      size="large"
      aria-label="WhatsApp"
      href={AffiliateLink}
      target="_blank"
    >
      <RedditIcon sx={{ color: "white", fontSize: "inherit" }} />
    </IconButton>
  );
};

const Telegram: React.FC<IWhatsApp> = ({ url }): JSX.Element => {
  const Link = "https://www.addtoany.com/add_to/telegram?linkurl=";
  const AffiliateLink = Link + encodeURIComponent(url);
  return (
    <IconButton
      sx={{
        backgroundColor: "darkGrey",
        "&:hover": { backgroundColor: "grey" },
      }}
      size="large"
      aria-label="WhatsApp"
      href={AffiliateLink}
      target="_blank"
    >
      <TelegramIcon sx={{ color: "white", fontSize: "inherit" }} />
    </IconButton>
  );
};

const Instagram: React.FC<IWhatsApp> = ({ url }): JSX.Element => {
  const Link = "https://www.addtoany.com/add_to/instagram?linkurl=";
  const AffiliateLink = Link + encodeURIComponent(url);
  return (
    <IconButton
      sx={{
        backgroundColor: "darkGrey",
        "&:hover": { backgroundColor: "grey" },
      }}
      size="large"
      aria-label="WhatsApp"
      href={AffiliateLink}
      target="_blank"
    >
      <InstagramIcon sx={{ color: "white", fontSize: "inherit" }} />
    </IconButton>
  );
};

interface IX {
  url: string;
}

const X: React.FC<IX> = ({ url }): JSX.Element => {
  const XLink = "https://x.com/intent/post?&text=Empfehlung&url=";
  const AffiliateLink = XLink + encodeURIComponent(url);
  return (
    <IconButton
      sx={{
        backgroundColor: "darkGrey",
        "&:hover": { backgroundColor: "grey" },
      }}
      size="large"
      aria-label="X"
      href={AffiliateLink}
      target="_blank"
    >
      <XIcon sx={{ color: "white", fontSize: "inherit" }} />
    </IconButton>
  );
};

interface ISwitch {
  vehicle: TVehicle;
  sx?: any;
}

export const Switch: React.FC<ISwitch> = ({
  sx = {},
  vehicle,
}): JSX.Element | null => {
  const [open, setOpen] = React.useState(false);

  const closeHandler = () => {
    setOpen(false);
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.2 }}
      >
        <Tooltip title="Fahrzeug teilen" placement="left" arrow color="primary">
          <IconButton
            color="primary"
            size="large"
            aria-label="Teilen"
            onClick={() => setOpen(true)}
            sx={sx}
          >
            <ShareIcon />
          </IconButton>
        </Tooltip>
        <Share {...{ vehicle, open, closeHandler }} />
      </motion.div>
    </>
  );
};

export default Switch;
