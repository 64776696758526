import { TVehicle } from "types";

interface IKilometerReducer {
  vehicles: TVehicle[];
  filter: any;
}

const KilometerReducer = ({ vehicles, filter }: IKilometerReducer) => {
  if (!filter.kilometer) return vehicles;

  return vehicles.filter(({ km }) => {
    return km && filter.kilometer[0] <= km && filter.kilometer[1] >= km;
  });
};

export default KilometerReducer;
