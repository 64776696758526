import React from "react";
import { useIntl } from "react-intl";

import Chip from "../../ChipIndicator";

interface IErstzulassungIndicator {
  filter: [number, number] | number | null;
  dispatch?: any;
}

const ErstzulassungIndicator = ({
  filter,
  dispatch,
}: IErstzulassungIndicator): JSX.Element | null => {
  const { formatMessage: t } = useIntl();

  if (!filter) return null;

  const label =
    Array.isArray(filter) && filter[0] !== filter[1]
      ? t(
          {
            id: "common.erstzulassung.span",
            defaultMessage: "{from} bis {to}",
          },
          { from: filter[0], to: filter[1] }
        )
      : Array.isArray(filter)
        ? filter[0]
        : filter;

  const handleDelete = (erstzulassung: any) => {
    dispatch({
      type: "UPDATE_FILTER",
      payload: { erstzulassung: null },
    });
  };

  return (
    <Chip
      id="erstzulassungChip"
      label={"EZ " + label}
      onDelete={() => handleDelete("erstzulassung")}
    />
  );
};

export default ErstzulassungIndicator;
