import React from "react";
import Cookies from "js-cookie";

interface IHistoryLogger {
  id: string | number;
}

export const cookies = Cookies.withConverter({
  read: function (value, name) {
    return JSON.parse(value);
  },
  write: function (value, name) {
    return JSON.stringify(value);
  },
});

const HistoryLogger: React.FC<IHistoryLogger> = ({ id }): null => {
  React.useEffect(() => {
    AddVehicleToHistory(id);
    // return () => console.log("Logged vehicle " + id);
  }, [id]);

  const AddVehicleToHistory = (id: string | number) => {
    const history = cookies.get("carbando.history") || [];
    if (!history.includes(id))
      cookies.set("carbando.history", [id, ...history]);
    else {
      cookies.set("carbando.history", [
        id,
        ...history.filter((e: string) => e !== id),
      ]);
    }
  };

  return null;
};

export default HistoryLogger;
