import React from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { TVehicle } from "types";
import { VehicleStore, FilterStore } from "stores";
import Card from "./ModellCard";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import BackToCatalog from "@mui/icons-material/Apps";

import Header from "./Header";
import { EmptySearch } from "../";

const Modell: React.FC = (): JSX.Element | null => {
  const { state: vehicles } = React.useContext(VehicleStore);
  const { state: filters } = React.useContext(FilterStore);

  const sortOrder = filters.filter.finance?.type === "lease" ? "rate" : "preis";

  const { modell_id } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!modell_id || !vehicles || vehicles.length === 0) return <EmptySearch />;

  const filteredVehicles = modell_id
    ? filterVehicles(vehicles, parseInt(modell_id))
    : vehicles;

  const sortedvehicles = filteredVehicles.sort((a: any, b: any) =>
    b[sortOrder] > a[sortOrder] ? -1 : 1
  );

  return (
    <>
      <Header modellId={modell_id} />
      {/* <Navigation /> */}
      <Box
        sx={{
          display: "grid",
          columnGap: { xs: 1, md: 2, m: 3 },
          rowGap: { xs: 2, md: 3, lg: 4 },
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
          },
        }}
      >
        {sortedvehicles.map((vehicle: TVehicle) => {
          return (
            <Card
              vehicle={vehicle}
              onClick={() => navigate("/catalog/dt/" + vehicle.wgnr)}
              key={vehicle.wgnr}
              variant={sortOrder}
              paperProps={{
                sx: {
                  borderRadius: 1,
                  ":hover": {
                    borderColor: "grey.800",
                    backgroundColor: "grey.50",
                    cursor: "pointer",
                  },
                },
              }}
            />
          );
        })}
      </Box>
    </>
  );
};

const filterVehicles = (vehicles: TVehicle[], id: number): any => {
  const results = vehicles.filter((v: any) => v.modell.id === id);
  return results;
};

const Navigation = () => (
  <Box
    sx={{
      display: "flex",
      position: "sticky",
      backgroundColor: "white",
      p: 1,
      mb: 2,
      top: 0,
      width: "100%",
    }}
  >
    <Button
      component={Link}
      to="/catalog"
      color="primary"
      startIcon={<BackToCatalog />}
    >
      <FormattedMessage id="navigation.catalog" />
    </Button>
  </Box>
);

export default Modell;
