import React from "react";
import { FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";

import Box from "@mui/material/Box";
import CheckIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Cancel";

import Footnote from "components/Footnote";

interface ITupel {
  label?: string;
  labelId?: string;
  text?: string;
  textId?: string;
  date?: string;
  unit?: string;
  number?: number;
  float?: number;
  percent?: number;
  boolean?: boolean;
  euro?: number;
  boxProps?: any;
  footnote?: number;
  force?: boolean;
}

const Tupel: React.FC<ITupel> = ({
  label,
  labelId,
  text,
  textId,
  number,
  float,
  unit,
  percent,
  date,
  boolean,
  euro,
  boxProps = {},
  footnote,
  force = false,
}): JSX.Element | null => {
  var LabelElement = <span>label</span>;

  if (label) {
    LabelElement = (
      <span>
        {label}&nbsp;{footnote && <Footnote id={footnote} />}
      </span>
    );
  } else if (labelId) {
    LabelElement = (
      <span>
        <FormattedMessage id={labelId} defaultMessage={`label-${labelId}`} />
        &nbsp;{footnote && <Footnote id={footnote} />}
      </span>
    );
  }

  var ValueElement = <span />;
  if (text !== undefined) {
    if ((!text || text.trim().length === 0) && !force === true) return null;
    ValueElement = <span>{text}</span>;
  } else if (textId !== undefined) {
    ValueElement = (
      <FormattedMessage id={textId} defaultMessage={`label-${textId}`} />
    );
  } else if (number !== undefined) {
    if (!number && !force === true) return null;
    ValueElement = <FormattedNumber value={number} />;
  } else if (float !== undefined) {
    if (!float && !force === true) return null;
    ValueElement = <FormattedNumber value={float} minimumFractionDigits={1} />;
  } else if (percent !== undefined) {
    if (!percent && !force === true) return null;
    ValueElement = (
      <FormattedNumber
        value={percent}
        // eslint-disable-next-line react/style-prop-object
        style="percent"
        minimumFractionDigits={2}
      />
    );
  } else if (euro !== undefined) {
    if (!euro && !force === true) return null;
    ValueElement = (
      // eslint-disable-next-line react/style-prop-object
      <FormattedNumber value={euro} style="currency" currency="EUR" />
    );
  } else if (date !== undefined) {
    if ((!date || date.trim().length === 0) && !force === true) return null;
    const dateString = date.length <= 8 ? "01/".replace("/", ".") + date : date;
    const dateObject = new Date(dateString);
    if (!dateObject) return null;
    ValueElement = (
      <FormattedDate
        value={dateObject}
        year="numeric"
        month="long"
        day="2-digit"
      />
    );
  } else if (boolean !== undefined) {
    ValueElement = boolean ? (
      <CheckIcon fontSize="inherit" sx={{ color: "success.main" }} />
    ) : (
      <CloseIcon fontSize="inherit" sx={{ color: "error.main" }} />
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        py: 1,
        borderBottom: 1,
        justifyContent: "space-between",
        ...boxProps,
      }}
    >
      {LabelElement}
      <span>
        {ValueElement}
        {unit && ` ${unit}`}
      </span>
    </Box>
  );
};

export default Tupel;
